import { CompanyClassification } from 'shared-domain';
import { CompanyClassificationTypeEnum } from '../type';

const isicClassifications: CompanyClassification[] = [
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'A',
    description: 'Agriculture, forestry and fishing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '01',
    description:
      'Crop and animal production, hunting and related service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '011',
    description: 'Growing of non-perennial crops',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0111',
    description:
      'Growing of cereals (except rice), leguminous crops and oil seeds',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0112',
    description: 'Growing of rice',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0113',
    description: 'Growing of vegetables and melons, roots and tubers',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0114',
    description: 'Growing of sugar cane',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0115',
    description: 'Growing of tobacco',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0116',
    description: 'Growing of fibre crops',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0119',
    description: 'Growing of other non-perennial crops',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '012',
    description: 'Growing of perennial crops',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0121',
    description: 'Growing of grapes',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0122',
    description: 'Growing of tropical and subtropical fruits',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0123',
    description: 'Growing of citrus fruits',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0124',
    description: 'Growing of pome fruits and stone fruits',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0125',
    description: 'Growing of other tree and bush fruits and nuts',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0126',
    description: 'Growing of oleaginous fruits',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0127',
    description: 'Growing of beverage crops',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0128',
    description: 'Growing of spices, aromatic, drug and pharmaceutical crops',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0129',
    description: 'Growing of other perennial crops',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '013',
    description: 'Plant propagation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0130',
    description: 'Plant propagation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '014',
    description: 'Animal production',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0141',
    description: 'Raising of cattle and buffaloes',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0142',
    description: 'Raising of horses and other equines',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0143',
    description: 'Raising of camels and camelids',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0144',
    description: 'Raising of sheep and goats',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0145',
    description: 'Raising of swine/pigs',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0146',
    description: 'Raising of poultry',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0149',
    description: 'Raising of other animals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '015',
    description: 'Mixed farming',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0150',
    description: 'Mixed farming',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '016',
    description:
      'Support activities to agriculture and post-harvest crop activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0161',
    description: 'Support activities for crop production',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0162',
    description: 'Support activities for animal production',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0163',
    description: 'Post-harvest crop activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0164',
    description: 'Seed processing for propagation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '017',
    description: 'Hunting, trapping and related service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0170',
    description: 'Hunting, trapping and related service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '02',
    description: 'Forestry and logging',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '021',
    description: 'Silviculture and other forestry activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0210',
    description: 'Silviculture and other forestry activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '022',
    description: 'Logging',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0220',
    description: 'Logging',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '023',
    description: 'Gathering of non-wood forest products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0230',
    description: 'Gathering of non-wood forest products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '024',
    description: 'Support services to forestry',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0240',
    description: 'Support services to forestry',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '03',
    description: 'Fishing and aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '031',
    description: 'Fishing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0311',
    description: 'Marine fishing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0312',
    description: 'Freshwater fishing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '032',
    description: 'Aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0321',
    description: 'Marine aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0322',
    description: 'Freshwater aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'B',
    description: 'Mining and quarrying',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '05',
    description: 'Mining of coal and lignite',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '051',
    description: 'Mining of hard coal',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0510',
    description: 'Mining of hard coal',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '052',
    description: 'Mining of lignite',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0520',
    description: 'Mining of lignite',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '06',
    description: 'Extraction of crude petroleum and natural gas',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '061',
    description: 'Extraction of crude petroleum',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0610',
    description: 'Extraction of crude petroleum',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '062',
    description: 'Extraction of natural gas',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0620',
    description: 'Extraction of natural gas',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '07',
    description: 'Mining of metal ores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '071',
    description: 'Mining of iron ores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0710',
    description: 'Mining of iron ores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '072',
    description: 'Mining of non-ferrous metal ores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0721',
    description: 'Mining of uranium and thorium ores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0729',
    description: 'Mining of other non-ferrous metal ores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '08',
    description: 'Other mining and quarrying',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '081',
    description: 'Quarrying of stone, sand and clay',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0810',
    description: 'Quarrying of stone, sand and clay',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '089',
    description: 'Mining and quarrying n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0891',
    description: 'Mining of chemical and fertilizer minerals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0892',
    description: 'Extraction of peat',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0893',
    description: 'Extraction of salt',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0899',
    description: 'Other mining and quarrying n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '09',
    description: 'Mining support service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '091',
    description: 'Support activities for petroleum and natural gas extraction',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0910',
    description: 'Support activities for petroleum and natural gas extraction',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '099',
    description: 'Support activities for other mining and quarrying',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '0990',
    description: 'Support activities for other mining and quarrying',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'C',
    description: 'Manufacturing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '10',
    description: 'Manufacture of food products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '101',
    description: 'Processing and preserving of meat',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1010',
    description: 'Processing and preserving of meat',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '102',
    description: 'Processing and preserving of fish, crustaceans and molluscs',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1020',
    description: 'Processing and preserving of fish, crustaceans and molluscs',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '103',
    description: 'Processing and preserving of fruit and vegetables',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1030',
    description: 'Processing and preserving of fruit and vegetables',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '104',
    description: 'Manufacture of vegetable and animal oils and fats',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1040',
    description: 'Manufacture of vegetable and animal oils and fats',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '105',
    description: 'Manufacture of dairy products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1050',
    description: 'Manufacture of dairy products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '106',
    description:
      'Manufacture of grain mill products, starches and starch products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1061',
    description: 'Manufacture of grain mill products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1062',
    description: 'Manufacture of starches and starch products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '107',
    description: 'Manufacture of other food products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1071',
    description: 'Manufacture of bakery products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1072',
    description: 'Manufacture of sugar',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1073',
    description: 'Manufacture of cocoa, chocolate and sugar confectionery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1074',
    description:
      'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1075',
    description: 'Manufacture of prepared meals and dishes',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1079',
    description: 'Manufacture of other food products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '108',
    description: 'Manufacture of prepared animal feeds',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1080',
    description: 'Manufacture of prepared animal feeds',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '11',
    description: 'Manufacture of beverages',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '110',
    description: 'Manufacture of beverages',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1101',
    description: 'Distilling, rectifying and blending of spirits',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1102',
    description: 'Manufacture of wines',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1103',
    description: 'Manufacture of malt liquors and malt',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1104',
    description:
      'Manufacture of soft drinks; production of mineral waters and other bottled waters',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '12',
    description: 'Manufacture of tobacco products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '120',
    description: 'Manufacture of tobacco products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1200',
    description: 'Manufacture of tobacco products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '13',
    description: 'Manufacture of textiles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '131',
    description: 'Spinning, weaving and finishing of textiles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1311',
    description: 'Preparation and spinning of textile fibres',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1312',
    description: 'Weaving of textiles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1313',
    description: 'Finishing of textiles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '139',
    description: 'Manufacture of other textiles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1391',
    description: 'Manufacture of knitted and crocheted fabrics',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1392',
    description: 'Manufacture of made-up textile articles, except apparel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1393',
    description: 'Manufacture of carpets and rugs',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1394',
    description: 'Manufacture of cordage, rope, twine and netting',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1399',
    description: 'Manufacture of other textiles n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '14',
    description: 'Manufacture of wearing apparel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '141',
    description: 'Manufacture of wearing apparel, except fur apparel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1410',
    description: 'Manufacture of wearing apparel, except fur apparel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '142',
    description: 'Manufacture of articles of fur',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1420',
    description: 'Manufacture of articles of fur',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '143',
    description: 'Manufacture of knitted and crocheted apparel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1430',
    description: 'Manufacture of knitted and crocheted apparel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '15',
    description: 'Manufacture of leather and related products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '151',
    description:
      'Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1511',
    description: 'Tanning and dressing of leather; dressing and dyeing of fur',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1512',
    description:
      'Manufacture of luggage, handbags and the like, saddlery and harness',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '152',
    description: 'Manufacture of footwear',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1520',
    description: 'Manufacture of footwear',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '16',
    description:
      'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '161',
    description: 'Sawmilling and planing of wood',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1610',
    description: 'Sawmilling and planing of wood',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '162',
    description:
      'Manufacture of products of wood, cork, straw and plaiting materials',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1621',
    description: 'Manufacture of veneer sheets and wood-based panels',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1622',
    description: "Manufacture of builders' carpentry and joinery",
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1623',
    description: 'Manufacture of wooden containers',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1629',
    description:
      'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '17',
    description: 'Manufacture of paper and paper products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '170',
    description: 'Manufacture of paper and paper products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1701',
    description: 'Manufacture of pulp, paper and paperboard',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1702',
    description:
      'Manufacture of corrugated paper and paperboard and of containers of paper and paperboard',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1709',
    description: 'Manufacture of other articles of paper and paperboard',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '18',
    description: 'Printing and reproduction of recorded media',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '181',
    description: 'Printing and service activities related to printing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1811',
    description: 'Printing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1812',
    description: 'Service activities related to printing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '182',
    description: 'Reproduction of recorded media',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1820',
    description: 'Reproduction of recorded media',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '19',
    description: 'Manufacture of coke and refined petroleum products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '191',
    description: 'Manufacture of coke oven products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1910',
    description: 'Manufacture of coke oven products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '192',
    description: 'Manufacture of refined petroleum products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '1920',
    description: 'Manufacture of refined petroleum products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '20',
    description: 'Manufacture of chemicals and chemical products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '201',
    description:
      'Manufacture of basic chemicals, fertilizers and nitrogen compounds, plastics and synthetic rubber in primary forms',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2011',
    description: 'Manufacture of basic chemicals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2012',
    description: 'Manufacture of fertilizers and nitrogen compounds',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2013',
    description:
      'Manufacture of plastics and synthetic rubber in primary forms',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '202',
    description: 'Manufacture of other chemical products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2021',
    description: 'Manufacture of pesticides and other agrochemical products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2022',
    description:
      'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2023',
    description:
      'Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2029',
    description: 'Manufacture of other chemical products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '203',
    description: 'Manufacture of man-made fibres',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2030',
    description: 'Manufacture of man-made fibres',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '21',
    description:
      'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '210',
    description:
      'Manufacture of pharmaceuticals, medicinal chemical and botanical products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2100',
    description:
      'Manufacture of pharmaceuticals, medicinal chemical and botanical products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '22',
    description: 'Manufacture of rubber and plastics products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '221',
    description: 'Manufacture of rubber products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2211',
    description:
      'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2219',
    description: 'Manufacture of other rubber products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '222',
    description: 'Manufacture of plastics products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2220',
    description: 'Manufacture of plastics products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '23',
    description: 'Manufacture of other non-metallic mineral products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '231',
    description: 'Manufacture of glass and glass products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2310',
    description: 'Manufacture of glass and glass products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '239',
    description: 'Manufacture of non-metallic mineral products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2391',
    description: 'Manufacture of refractory products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2392',
    description: 'Manufacture of clay building materials',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2393',
    description: 'Manufacture of other porcelain and ceramic products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2394',
    description: 'Manufacture of cement, lime and plaster',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2395',
    description: 'Manufacture of articles of concrete, cement and plaster',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2396',
    description: 'Cutting, shaping and finishing of stone',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2399',
    description: 'Manufacture of other non-metallic mineral products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '24',
    description: 'Manufacture of basic metals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '241',
    description: 'Manufacture of basic iron and steel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2410',
    description: 'Manufacture of basic iron and steel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '242',
    description: 'Manufacture of basic precious and other non-ferrous metals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2420',
    description: 'Manufacture of basic precious and other non-ferrous metals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '243',
    description: 'Casting of metals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2431',
    description: 'Casting of iron and steel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2432',
    description: 'Casting of non-ferrous metals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '25',
    description:
      'Manufacture of fabricated metal products, except machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '251',
    description:
      'Manufacture of structural metal products, tanks, reservoirs and steam generators',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2511',
    description: 'Manufacture of structural metal products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2512',
    description: 'Manufacture of tanks, reservoirs and containers of metal',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2513',
    description:
      'Manufacture of steam generators, except central heating hot water boilers',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '252',
    description: 'Manufacture of weapons and ammunition',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2520',
    description: 'Manufacture of weapons and ammunition',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '259',
    description:
      'Manufacture of other fabricated metal products; metalworking service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2591',
    description:
      'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2592',
    description: 'Treatment and coating of metals; machining',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2593',
    description: 'Manufacture of cutlery, hand tools and general hardware',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2599',
    description: 'Manufacture of other fabricated metal products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '26',
    description: 'Manufacture of computer, electronic and optical products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '261',
    description: 'Manufacture of electronic components and boards',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2610',
    description: 'Manufacture of electronic components and boards',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '262',
    description: 'Manufacture of computers and peripheral equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2620',
    description: 'Manufacture of computers and peripheral equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '263',
    description: 'Manufacture of communication equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2630',
    description: 'Manufacture of communication equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '264',
    description: 'Manufacture of consumer electronics',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2640',
    description: 'Manufacture of consumer electronics',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '265',
    description:
      'Manufacture of measuring, testing, navigating and control equipment; watches and clocks',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2651',
    description:
      'Manufacture of measuring, testing, navigating and control equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2652',
    description: 'Manufacture of watches and clocks',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '266',
    description:
      'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2660',
    description:
      'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '267',
    description:
      'Manufacture of optical instruments and photographic equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2670',
    description:
      'Manufacture of optical instruments and photographic equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '268',
    description: 'Manufacture of magnetic and optical media',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2680',
    description: 'Manufacture of magnetic and optical media',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '27',
    description: 'Manufacture of electrical equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '271',
    description:
      'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2710',
    description:
      'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '272',
    description: 'Manufacture of batteries and accumulators',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2720',
    description: 'Manufacture of batteries and accumulators',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '273',
    description: 'Manufacture of wiring and wiring devices',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2731',
    description: 'Manufacture of fibre optic cables',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2732',
    description:
      'Manufacture of other electronic and electric wires and cables',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2733',
    description: 'Manufacture of wiring devices',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '274',
    description: 'Manufacture of electric lighting equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2740',
    description: 'Manufacture of electric lighting equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '275',
    description: 'Manufacture of domestic appliances',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2750',
    description: 'Manufacture of domestic appliances',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '279',
    description: 'Manufacture of other electrical equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2790',
    description: 'Manufacture of other electrical equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '28',
    description: 'Manufacture of machinery and equipment n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '281',
    description: 'Manufacture of general-purpose machinery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2811',
    description:
      'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2812',
    description: 'Manufacture of fluid power equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2813',
    description: 'Manufacture of other pumps, compressors, taps and valves',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2814',
    description: 'Manufacture of bearings, gears, gearing and driving elements',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2815',
    description: 'Manufacture of ovens, furnaces and furnace burners',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2816',
    description: 'Manufacture of lifting and handling equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2817',
    description:
      'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2818',
    description: 'Manufacture of power-driven hand tools',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2819',
    description: 'Manufacture of other general-purpose machinery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '282',
    description: 'Manufacture of special-purpose machinery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2821',
    description: 'Manufacture of agricultural and forestry machinery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2822',
    description: 'Manufacture of metal-forming machinery and machine tools',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2823',
    description: 'Manufacture of machinery for metallurgy',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2824',
    description:
      'Manufacture of machinery for mining, quarrying and construction',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2825',
    description:
      'Manufacture of machinery for food, beverage and tobacco processing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2826',
    description:
      'Manufacture of machinery for textile, apparel and leather production',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2829',
    description: 'Manufacture of other special-purpose machinery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '29',
    description: 'Manufacture of motor vehicles, trailers and semi-trailers',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '291',
    description: 'Manufacture of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2910',
    description: 'Manufacture of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '292',
    description:
      'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2920',
    description:
      'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '293',
    description: 'Manufacture of parts and accessories for motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '2930',
    description: 'Manufacture of parts and accessories for motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '30',
    description: 'Manufacture of other transport equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '301',
    description: 'Building of ships and boats',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3011',
    description: 'Building of ships and floating structures',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3012',
    description: 'Building of pleasure and sporting boats',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '302',
    description: 'Manufacture of railway locomotives and rolling stock',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3020',
    description: 'Manufacture of railway locomotives and rolling stock',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '303',
    description: 'Manufacture of air and spacecraft and related machinery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3030',
    description: 'Manufacture of air and spacecraft and related machinery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '304',
    description: 'Manufacture of military fighting vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3040',
    description: 'Manufacture of military fighting vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '309',
    description: 'Manufacture of transport equipment n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3091',
    description: 'Manufacture of motorcycles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3092',
    description: 'Manufacture of bicycles and invalid carriages',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3099',
    description: 'Manufacture of other transport equipment n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '31',
    description: 'Manufacture of furniture',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '310',
    description: 'Manufacture of furniture',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3100',
    description: 'Manufacture of furniture',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '32',
    description: 'Other manufacturing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '321',
    description: 'Manufacture of jewellery, bijouterie and related articles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3211',
    description: 'Manufacture of jewellery and related articles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3212',
    description: 'Manufacture of imitation jewellery and related articles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '322',
    description: 'Manufacture of musical instruments',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3220',
    description: 'Manufacture of musical instruments',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '323',
    description: 'Manufacture of sports goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3230',
    description: 'Manufacture of sports goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '324',
    description: 'Manufacture of games and toys',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3240',
    description: 'Manufacture of games and toys',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '325',
    description: 'Manufacture of medical and dental instruments and supplies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3250',
    description: 'Manufacture of medical and dental instruments and supplies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '329',
    description: 'Other manufacturing n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3290',
    description: 'Other manufacturing n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '33',
    description: 'Repair and installation of machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '331',
    description: 'Repair of fabricated metal products, machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3311',
    description: 'Repair of fabricated metal products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3312',
    description: 'Repair of machinery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3313',
    description: 'Repair of electronic and optical equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3314',
    description: 'Repair of electrical equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3315',
    description: 'Repair of transport equipment, except motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3319',
    description: 'Repair of other equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '332',
    description: 'Installation of industrial machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3320',
    description: 'Installation of industrial machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'D',
    description: 'Electricity, gas, steam and air conditioning supply',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '35',
    description: 'Electricity, gas, steam and air conditioning supply',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '351',
    description: 'Electric power generation, transmission and distribution',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3510',
    description: 'Electric power generation, transmission and distribution',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '352',
    description:
      'Manufacture of gas; distribution of gaseous fuels through mains',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3520',
    description:
      'Manufacture of gas; distribution of gaseous fuels through mains',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '353',
    description: 'Steam and air conditioning supply',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3530',
    description: 'Steam and air conditioning supply',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'E',
    description:
      'Water supply; sewerage, waste management and remediation activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '36',
    description: 'Water collection, treatment and supply',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '360',
    description: 'Water collection, treatment and supply',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3600',
    description: 'Water collection, treatment and supply',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '37',
    description: 'Sewerage',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '370',
    description: 'Sewerage',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3700',
    description: 'Sewerage',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '38',
    description:
      'Waste collection, treatment and disposal activities; materials recovery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '381',
    description: 'Waste collection',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3811',
    description: 'Collection of non-hazardous waste',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3812',
    description: 'Collection of hazardous waste',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '382',
    description: 'Waste treatment and disposal',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3821',
    description: 'Treatment and disposal of non-hazardous waste',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3822',
    description: 'Treatment and disposal of hazardous waste',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '383',
    description: 'Materials recovery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3830',
    description: 'Materials recovery',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '39',
    description: 'Remediation activities and other waste management services',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '390',
    description: 'Remediation activities and other waste management services',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '3900',
    description: 'Remediation activities and other waste management services',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'F',
    description: 'Construction',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '41',
    description: 'Construction of buildings',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '410',
    description: 'Construction of buildings',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4100',
    description: 'Construction of buildings',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '42',
    description: 'Civil engineering',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '421',
    description: 'Construction of roads and railways',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4210',
    description: 'Construction of roads and railways',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '422',
    description: 'Construction of utility projects',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4220',
    description: 'Construction of utility projects',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '429',
    description: 'Construction of other civil engineering projects',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4290',
    description: 'Construction of other civil engineering projects',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '43',
    description: 'Specialized construction activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '431',
    description: 'Demolition and site preparation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4311',
    description: 'Demolition',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4312',
    description: 'Site preparation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '432',
    description:
      'Electrical, plumbing and other construction installation activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4321',
    description: 'Electrical installation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4322',
    description: 'Plumbing, heat and air-conditioning installation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4329',
    description: 'Other construction installation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '433',
    description: 'Building completion and finishing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4330',
    description: 'Building completion and finishing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '439',
    description: 'Other specialized construction activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4390',
    description: 'Other specialized construction activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'G',
    description:
      'Wholesale and retail trade; repair of motor vehicles and motorcycles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '45',
    description:
      'Wholesale and retail trade and repair of motor vehicles and motorcycles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '451',
    description: 'Sale of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4510',
    description: 'Sale of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '452',
    description: 'Maintenance and repair of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4520',
    description: 'Maintenance and repair of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '453',
    description: 'Sale of motor vehicle parts and accessories',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4530',
    description: 'Sale of motor vehicle parts and accessories',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '454',
    description:
      'Sale, maintenance and repair of motorcycles and related parts and accessories',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4540',
    description:
      'Sale, maintenance and repair of motorcycles and related parts and accessories',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '46',
    description: 'Wholesale trade, except of motor vehicles and motorcycles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '461',
    description: 'Wholesale on a fee or contract basis',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4610',
    description: 'Wholesale on a fee or contract basis',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '462',
    description: 'Wholesale of agricultural raw materials and live animals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4620',
    description: 'Wholesale of agricultural raw materials and live animals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '463',
    description: 'Wholesale of food, beverages and tobacco',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4630',
    description: 'Wholesale of food, beverages and tobacco',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '464',
    description: 'Wholesale of household goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4641',
    description: 'Wholesale of textiles, clothing and footwear',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4649',
    description: 'Wholesale of other household goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '465',
    description: 'Wholesale of machinery, equipment and supplies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4651',
    description:
      'Wholesale of computers, computer peripheral equipment and software',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4652',
    description:
      'Wholesale of electronic and telecommunications equipment and parts',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4653',
    description: 'Wholesale of agricultural machinery, equipment and supplies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4659',
    description: 'Wholesale of other machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '466',
    description: 'Other specialized wholesale',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4661',
    description:
      'Wholesale of solid, liquid and gaseous fuels and related products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4662',
    description: 'Wholesale of metals and metal ores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4663',
    description:
      'Wholesale of construction materials, hardware, plumbing and heating equipment and supplies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4669',
    description: 'Wholesale of waste and scrap and other products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '469',
    description: 'Non-specialized wholesale trade',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4690',
    description: 'Non-specialized wholesale trade',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '47',
    description: 'Retail trade, except of motor vehicles and motorcycles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '471',
    description: 'Retail sale in non-specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4711',
    description:
      'Retail sale in non-specialized stores with food, beverages or tobacco predominating',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4719',
    description: 'Other retail sale in non-specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '472',
    description:
      'Retail sale of food, beverages and tobacco in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4721',
    description: 'Retail sale of food in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4722',
    description: 'Retail sale of beverages in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4723',
    description: 'Retail sale of tobacco products in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '473',
    description: 'Retail sale of automotive fuel in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4730',
    description: 'Retail sale of automotive fuel in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '474',
    description:
      'Retail sale of information and communications equipment in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4741',
    description:
      'Retail sale of computers, peripheral units, software and telecommunications equipment in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4742',
    description:
      'Retail sale of audio and video equipment in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '475',
    description:
      'Retail sale of other household equipment in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4751',
    description: 'Retail sale of textiles in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4752',
    description:
      'Retail sale of hardware, paints and glass in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4753',
    description:
      'Retail sale of carpets, rugs, wall and floor coverings in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4759',
    description:
      'Retail sale of electrical household appliances, furniture, lighting equipment and other household articles in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '476',
    description:
      'Retail sale of cultural and recreation goods in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4761',
    description:
      'Retail sale of books, newspapers and stationary in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4762',
    description:
      'Retail sale of music and video recordings in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4763',
    description: 'Retail sale of sporting equipment in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4764',
    description: 'Retail sale of games and toys in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '477',
    description: 'Retail sale of other goods in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4771',
    description:
      'Retail sale of clothing, footwear and leather articles in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4772',
    description:
      'Retail sale of pharmaceutical and medical goods, cosmetic and toilet articles in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4773',
    description: 'Other retail sale of new goods in specialized stores',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4774',
    description: 'Retail sale of second-hand goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '478',
    description: 'Retail sale via stalls and markets',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4781',
    description:
      'Retail sale via stalls and markets of food, beverages and tobacco products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4782',
    description:
      'Retail sale via stalls and markets of textiles, clothing and footwear',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4789',
    description: 'Retail sale via stalls and markets of other goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '479',
    description: 'Retail trade not in stores, stalls or markets',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4791',
    description: 'Retail sale via mail order houses or via Internet',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4799',
    description: 'Other retail sale not in stores, stalls or markets',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'H',
    description: 'Transportation and storage',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '49',
    description: 'Land transport and transport via pipelines',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '491',
    description: 'Transport via railways',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4911',
    description: 'Passenger rail transport, interurban',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4912',
    description: 'Freight rail transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '492',
    description: 'Other land transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4921',
    description: 'Urban and suburban passenger land transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4922',
    description: 'Other passenger land transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4923',
    description: 'Freight transport by road',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '493',
    description: 'Transport via pipeline',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '4930',
    description: 'Transport via pipeline',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '50',
    description: 'Water transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '501',
    description: 'Sea and coastal water transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5011',
    description: 'Sea and coastal passenger water transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5012',
    description: 'Sea and coastal freight water transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '502',
    description: 'Inland water transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5021',
    description: 'Inland passenger water transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5022',
    description: 'Inland freight water transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '51',
    description: 'Air transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '511',
    description: 'Passenger air transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5110',
    description: 'Passenger air transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '512',
    description: 'Freight air transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5120',
    description: 'Freight air transport',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '52',
    description: 'Warehousing and support activities for transportation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '521',
    description: 'Warehousing and storage',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5210',
    description: 'Warehousing and storage',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '522',
    description: 'Support activities for transportation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5221',
    description: 'Service activities incidental to land transportation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5222',
    description: 'Service activities incidental to water transportation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5223',
    description: 'Service activities incidental to air transportation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5224',
    description: 'Cargo handling',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5229',
    description: 'Other transportation support activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '53',
    description: 'Postal and courier activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '531',
    description: 'Postal activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5310',
    description: 'Postal activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '532',
    description: 'Courier activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5320',
    description: 'Courier activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'I',
    description: 'Accommodation and food service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '55',
    description: 'Accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '551',
    description: 'Short term accommodation activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5510',
    description: 'Short term accommodation activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '552',
    description:
      'Camping grounds, recreational vehicle parks and trailer parks',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5520',
    description:
      'Camping grounds, recreational vehicle parks and trailer parks',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '559',
    description: 'Other accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5590',
    description: 'Other accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '56',
    description: 'Food and beverage service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '561',
    description: 'Restaurants and mobile food service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5610',
    description: 'Restaurants and mobile food service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '562',
    description: 'Event catering and other food service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5621',
    description: 'Event catering',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5629',
    description: 'Other food service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '563',
    description: 'Beverage serving activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5630',
    description: 'Beverage serving activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'J',
    description: 'Information and communication',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '58',
    description: 'Publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '581',
    description:
      'Publishing of books, periodicals and other publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5811',
    description: 'Book publishing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5812',
    description: 'Publishing of directories and mailing lists',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5813',
    description: 'Publishing of newspapers, journals and periodicals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5819',
    description: 'Other publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '582',
    description: 'Software publishing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5820',
    description: 'Software publishing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '59',
    description:
      'Motion picture, video and television programme production, sound recording and music publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '591',
    description: 'Motion picture, video and television programme activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5911',
    description:
      'Motion picture, video and television programme production activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5912',
    description:
      'Motion picture, video and television programme post-production activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5913',
    description:
      'Motion picture, video and television programme distribution activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5914',
    description: 'Motion picture projection activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '592',
    description: 'Sound recording and music publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '5920',
    description: 'Sound recording and music publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '60',
    description: 'Programming and broadcasting activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '601',
    description: 'Radio broadcasting',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6010',
    description: 'Radio broadcasting',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '602',
    description: 'Television programming and broadcasting activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6020',
    description: 'Television programming and broadcasting activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '61',
    description: 'Telecommunications',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '611',
    description: 'Wired telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6110',
    description: 'Wired telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '612',
    description: 'Wireless telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6120',
    description: 'Wireless telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '613',
    description: 'Satellite telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6130',
    description: 'Satellite telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '619',
    description: 'Other telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6190',
    description: 'Other telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '62',
    description: 'Computer programming, consultancy and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '620',
    description: 'Computer programming, consultancy and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6201',
    description: 'Computer programming activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6202',
    description:
      'Computer consultancy and computer facilities management activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6209',
    description: 'Other information technology and computer service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '63',
    description: 'Information service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '631',
    description: 'Data processing, hosting and related activities; web portals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6311',
    description: 'Data processing, hosting and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6312',
    description: 'Web portals',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '639',
    description: 'Other information service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6391',
    description: 'News agency activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6399',
    description: 'Other information service activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'K',
    description: 'Financial and insurance activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '64',
    description:
      'Financial service activities, except insurance and pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '641',
    description: 'Monetary intermediation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6411',
    description: 'Central banking',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6419',
    description: 'Other monetary intermediation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '642',
    description: 'Activities of holding companies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6420',
    description: 'Activities of holding companies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '643',
    description: 'Trusts, funds and similar financial entities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6430',
    description: 'Trusts, funds and similar financial entities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '649',
    description:
      'Other financial service activities, except insurance and pension funding activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6491',
    description: 'Financial leasing',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6492',
    description: 'Other credit granting',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6499',
    description:
      'Other financial service activities, except insurance and pension funding activities, n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '65',
    description:
      'Insurance, reinsurance and pension funding, except compulsory social security',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '651',
    description: 'Insurance',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6511',
    description: 'Life insurance',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6512',
    description: 'Non-life insurance',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '652',
    description: 'Reinsurance',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6520',
    description: 'Reinsurance',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '653',
    description: 'Pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6530',
    description: 'Pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '66',
    description:
      'Activities auxiliary to financial service and insurance activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '661',
    description:
      'Activities auxiliary to financial service activities, except insurance and pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6611',
    description: 'Administration of financial markets',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6612',
    description: 'Security and commodity contracts brokerage',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6619',
    description: 'Other activities auxiliary to financial service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '662',
    description: 'Activities auxiliary to insurance and pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6621',
    description: 'Risk and damage evaluation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6622',
    description: 'Activities of insurance agents and brokers',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6629',
    description: 'Other activities auxiliary to insurance and pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '663',
    description: 'Fund management activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6630',
    description: 'Fund management activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'L',
    description: 'Real estate activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '68',
    description: 'Real estate activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '681',
    description: 'Real estate activities with own or leased property',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6810',
    description: 'Real estate activities with own or leased property',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '682',
    description: 'Real estate activities on a fee or contract basis',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6820',
    description: 'Real estate activities on a fee or contract basis',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'M',
    description: 'Professional, scientific and technical activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '69',
    description: 'Legal and accounting activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '691',
    description: 'Legal activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6910',
    description: 'Legal activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '692',
    description:
      'Accounting, bookkeeping and auditing activities; tax consultancy',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '6920',
    description:
      'Accounting, bookkeeping and auditing activities; tax consultancy',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '70',
    description:
      'Activities of head offices; management consultancy activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '701',
    description: 'Activities of head offices',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7010',
    description: 'Activities of head offices',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '702',
    description: 'Management consultancy activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7020',
    description: 'Management consultancy activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '71',
    description:
      'Architectural and engineering activities; technical testing and analysis',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '711',
    description:
      'Architectural and engineering activities and related technical consultancy',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7110',
    description:
      'Architectural and engineering activities and related technical consultancy',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '712',
    description: 'Technical testing and analysis',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7120',
    description: 'Technical testing and analysis',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '72',
    description: 'Scientific research and development',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '721',
    description:
      'Research and experimental development on natural sciences and engineering',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7210',
    description:
      'Research and experimental development on natural sciences and engineering',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '722',
    description:
      'Research and experimental development on social sciences and humanities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7220',
    description:
      'Research and experimental development on social sciences and humanities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '73',
    description: 'Advertising and market research',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '731',
    description: 'Advertising',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7310',
    description: 'Advertising',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '732',
    description: 'Market research and public opinion polling',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7320',
    description: 'Market research and public opinion polling',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '74',
    description: 'Other professional, scientific and technical activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '741',
    description: 'Specialized design activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7410',
    description: 'Specialized design activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '742',
    description: 'Photographic activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7420',
    description: 'Photographic activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '749',
    description:
      'Other professional, scientific and technical activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7490',
    description:
      'Other professional, scientific and technical activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '75',
    description: 'Veterinary activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '750',
    description: 'Veterinary activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7500',
    description: 'Veterinary activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'N',
    description: 'Administrative and support service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '77',
    description: 'Rental and leasing activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '771',
    description: 'Renting and leasing of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7710',
    description: 'Renting and leasing of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '772',
    description: 'Renting and leasing of personal and household goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7721',
    description: 'Renting and leasing of recreational and sports goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7722',
    description: 'Renting of video tapes and disks',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7729',
    description: 'Renting and leasing of other personal and household goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '773',
    description:
      'Renting and leasing of other machinery, equipment and tangible goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7730',
    description:
      'Renting and leasing of other machinery, equipment and tangible goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '774',
    description:
      'Leasing of intellectual property and similar products, except copyrighted works',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7740',
    description:
      'Leasing of intellectual property and similar products, except copyrighted works',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '78',
    description: 'Employment activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '781',
    description: 'Activities of employment placement agencies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7810',
    description: 'Activities of employment placement agencies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '782',
    description: 'Temporary employment agency activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7820',
    description: 'Temporary employment agency activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '783',
    description: 'Other human resources provision',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7830',
    description: 'Other human resources provision',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '79',
    description:
      'Travel agency, tour operator, reservation service and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '791',
    description: 'Travel agency and tour operator activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7911',
    description: 'Travel agency activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7912',
    description: 'Tour operator activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '799',
    description: 'Other reservation service and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '7990',
    description: 'Other reservation service and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '80',
    description: 'Security and investigation activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '801',
    description: 'Private security activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8010',
    description: 'Private security activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '802',
    description: 'Security systems service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8020',
    description: 'Security systems service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '803',
    description: 'Investigation activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8030',
    description: 'Investigation activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '81',
    description: 'Services to buildings and landscape activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '811',
    description: 'Combined facilities support activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8110',
    description: 'Combined facilities support activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '812',
    description: 'Cleaning activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8121',
    description: 'General cleaning of buildings',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8129',
    description: 'Other building and industrial cleaning activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '813',
    description: 'Landscape care and maintenance service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8130',
    description: 'Landscape care and maintenance service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '82',
    description:
      'Office administrative, office support and other business support activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '821',
    description: 'Office administrative and support activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8211',
    description: 'Combined office administrative service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8219',
    description:
      'Photocopying, document preparation and other specialized office support activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '822',
    description: 'Activities of call centres',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8220',
    description: 'Activities of call centres',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '823',
    description: 'Organization of conventions and trade shows',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8230',
    description: 'Organization of conventions and trade shows',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '829',
    description: 'Business support service activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8291',
    description: 'Activities of collection agencies and credit bureaus',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8292',
    description: 'Packaging activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8299',
    description: 'Other business support service activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'O',
    description:
      'Public administration and defence; compulsory social security',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '84',
    description:
      'Public administration and defence; compulsory social security',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '841',
    description:
      'Administration of the State and the economic and social policy of the community',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8411',
    description: 'General public administration activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8412',
    description:
      'Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8413',
    description:
      'Regulation of and contribution to more efficient operation of businesses',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '842',
    description: 'Provision of services to the community as a whole',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8421',
    description: 'Foreign affairs',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8422',
    description: 'Defence activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8423',
    description: 'Public order and safety activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '843',
    description: 'Compulsory social security activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8430',
    description: 'Compulsory social security activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'P',
    description: 'Education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '85',
    description: 'Education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '851',
    description: 'Pre-primary and primary education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8510',
    description: 'Pre-primary and primary education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '852',
    description: 'Secondary education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8521',
    description: 'General secondary education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8522',
    description: 'Technical and vocational secondary education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '853',
    description: 'Higher education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8530',
    description: 'Higher education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '854',
    description: 'Other education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8541',
    description: 'Sports and recreation education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8542',
    description: 'Cultural education',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8549',
    description: 'Other education n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '855',
    description: 'Educational support activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8550',
    description: 'Educational support activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'Q',
    description: 'Human health and social work activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '86',
    description: 'Human health activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '861',
    description: 'Hospital activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8610',
    description: 'Hospital activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '862',
    description: 'Medical and dental practice activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8620',
    description: 'Medical and dental practice activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '869',
    description: 'Other human health activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8690',
    description: 'Other human health activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '87',
    description: 'Residential care activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '871',
    description: 'Residential nursing care facilities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8710',
    description: 'Residential nursing care facilities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '872',
    description:
      'Residential care activities for mental retardation, mental health and substance abuse',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8720',
    description:
      'Residential care activities for mental retardation, mental health and substance abuse',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '873',
    description: 'Residential care activities for the elderly and disabled',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8730',
    description: 'Residential care activities for the elderly and disabled',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '879',
    description: 'Other residential care activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8790',
    description: 'Other residential care activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '88',
    description: 'Social work activities without accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '881',
    description:
      'Social work activities without accommodation for the elderly and disabled',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8810',
    description:
      'Social work activities without accommodation for the elderly and disabled',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '889',
    description: 'Other social work activities without accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '8890',
    description: 'Other social work activities without accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'R',
    description: 'Arts, entertainment and recreation',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '90',
    description: 'Creative, arts and entertainment activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '900',
    description: 'Creative, arts and entertainment activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9000',
    description: 'Creative, arts and entertainment activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '91',
    description: 'Libraries, archives, museums and other cultural activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '910',
    description: 'Libraries, archives, museums and other cultural activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9101',
    description: 'Library and archives activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9102',
    description:
      'Museums activities and operation of historical sites and buildings',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9103',
    description:
      'Botanical and zoological gardens and nature reserves activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '92',
    description: 'Gambling and betting activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '920',
    description: 'Gambling and betting activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9200',
    description: 'Gambling and betting activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '93',
    description: 'Sports activities and amusement and recreation activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '931',
    description: 'Sports activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9311',
    description: 'Operation of sports facilities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9312',
    description: 'Activities of sports clubs',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9319',
    description: 'Other sports activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '932',
    description: 'Other amusement and recreation activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9321',
    description: 'Activities of amusement parks and theme parks',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9329',
    description: 'Other amusement and recreation activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'S',
    description: 'Other service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '94',
    description: 'Activities of membership organizations',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '941',
    description:
      'Activities of business, employers and professional membership organizations',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9411',
    description:
      'Activities of business and employers membership organizations',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9412',
    description: 'Activities of professional membership organizations',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '942',
    description: 'Activities of trade unions',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9420',
    description: 'Activities of trade unions',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '949',
    description: 'Activities of other membership organizations',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9491',
    description: 'Activities of religious organizations',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9492',
    description: 'Activities of political organizations',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9499',
    description: 'Activities of other membership organizations n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '95',
    description: 'Repair of computers and personal and household goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '951',
    description: 'Repair of computers and communication equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9511',
    description: 'Repair of computers and peripheral equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9512',
    description: 'Repair of communication equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '952',
    description: 'Repair of personal and household goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9521',
    description: 'Repair of consumer electronics',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9522',
    description: 'Repair of household appliances and home and garden equipment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9523',
    description: 'Repair of footwear and leather goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9524',
    description: 'Repair of furniture and home furnishings',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9529',
    description: 'Repair of other personal and household goods',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '96',
    description: 'Other personal service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '960',
    description: 'Other personal service activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9601',
    description: 'Washing and (dry-) cleaning of textile and fur products',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9602',
    description: 'Hairdressing and other beauty treatment',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9603',
    description: 'Funeral and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9609',
    description: 'Other personal service activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'T',
    description:
      'Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '97',
    description: 'Activities of households as employers of domestic personnel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '970',
    description: 'Activities of households as employers of domestic personnel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9700',
    description: 'Activities of households as employers of domestic personnel',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '98',
    description:
      'Undifferentiated goods- and services-producing activities of private households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '981',
    description:
      'Undifferentiated goods-producing activities of private households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9810',
    description:
      'Undifferentiated goods-producing activities of private households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '982',
    description:
      'Undifferentiated service-producing activities of private households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9820',
    description:
      'Undifferentiated service-producing activities of private households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: 'U',
    description: 'Activities of extraterritorial organizations and bodies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '99',
    description: 'Activities of extraterritorial organizations and bodies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '990',
    description: 'Activities of extraterritorial organizations and bodies',
  },
  {
    type: CompanyClassificationTypeEnum.ISIC,
    code: '9900',
    description: 'Activities of extraterritorial organizations and bodies',
  },
];

export default isicClassifications;
