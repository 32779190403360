import { CompanyClassification } from 'shared-domain';
import { CompanyClassificationTypeEnum } from '../type';

const sicClassifications: CompanyClassification[] = [
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0111',
    description: 'Wheat',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0112',
    description: 'Rice',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0115',
    description: 'Corn',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0116',
    description: 'Soybeans',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0119',
    description: 'Cash Grains, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0131',
    description: 'Cotton',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0132',
    description: 'Tobacco',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0133',
    description: 'Sugarcane and Sugar Beets',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0134',
    description: 'Irish Potatoes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0139',
    description: 'Field Crops, Except Cash Grains, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0161',
    description: 'Vegetables and Melons',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0171',
    description: 'Berry Crops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0172',
    description: 'Grapes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0173',
    description: 'Tree Nuts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0174',
    description: 'Citrus Fruits',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0175',
    description: 'Deciduous Tree Fruits',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0179',
    description: 'Fruits and Tree Nuts, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0181',
    description: 'Ornamental Floriculture and Nursery Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0182',
    description: 'Food Crops Grown Under Cover',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0191',
    description: 'General Farms, Primarily Crop',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0211',
    description: 'Beef Cattle Feedlots',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0212',
    description: 'Beef Cattle, Except Feedlots',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0213',
    description: 'Hogs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0214',
    description: 'Sheep and Goats',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0219',
    description: 'General Livestock, Except Dairy and Poultry',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0241',
    description: 'Dairy Farms',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0251',
    description: 'Broiler, Fryer, and Roaster Chickens',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0252',
    description: 'Chicken Eggs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0253',
    description: 'Turkeys and Turkey Eggs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0254',
    description: 'Poultry Hatcheries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0259',
    description: 'Poultry and Eggs, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0271',
    description: 'Fur-Bearing Animals and Rabbits',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0272',
    description: 'Horses and Other Equines',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0273',
    description: 'Animal Aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0279',
    description: 'Animal Specialties, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0291',
    description: 'General Farms, Primarily Livestock and Animal Specialties',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0711',
    description: 'Soil Preparation Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0721',
    description: 'Crop Planting, Cultivating, and Protecting',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0722',
    description: 'Crop Harvesting, Primarily by Machine',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0723',
    description: 'Crop Preparation Services for Market, Except Cotton Ginning',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0724',
    description: 'Cotton Ginning',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0741',
    description: 'Veterinary Services for Livestock',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0742',
    description: 'Veterinary Services for Animal Specialties',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0751',
    description: 'Livestock Services, Except Veterinary',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0752',
    description: 'Animal Specialty Services, Except Veterinary',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0761',
    description: 'Farm Labor Contractors and Crew Leaders',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0762',
    description: 'Farm Management Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0781',
    description: 'Landscape Counseling and Planning',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0782',
    description: 'Lawn and Garden Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0783',
    description: 'Ornamental Shrub and Tree Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0811',
    description: 'Timber Tracts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0831',
    description: 'Forest Nurseries and Gathering of Forest Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0851',
    description: 'Forestry Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0912',
    description: 'Finfish',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0913',
    description: 'Shellfish',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0919',
    description: 'Miscellaneous Marine Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0921',
    description: 'Fish Hatcheries and Preserves',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '0971',
    description: 'Hunting and Trapping, and Game Propagation',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1011',
    description: 'Iron Ores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1021',
    description: 'Copper Ores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1031',
    description: 'Lead and Zinc Ores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1041',
    description: 'Gold Ores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1044',
    description: 'Silver Ores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1061',
    description: 'Ferroalloy Ores, Except Vanadium',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1081',
    description: 'Metal Mining Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1094',
    description: 'Uranium-Radium-Vanadium Ores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1099',
    description: 'Miscellaneous Metal Ores, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1221',
    description: 'Bituminous Coal and Lignite Surface Mining',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1222',
    description: 'Bituminous Coal Underground Mining',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1231',
    description: 'Anthracite Mining',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1241',
    description: 'Coal Mining Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1311',
    description: 'Crude Petroleum and Natural Gas',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1321',
    description: 'Natural Gas Liquids',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1381',
    description: 'Drilling Oil and Gas Wells',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1382',
    description: 'Oil and Gas Field Exploration Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1389',
    description: 'Oil and Gas Field Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1411',
    description: 'Dimension Stone',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1422',
    description: 'Crushed and Broken Limestone',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1423',
    description: 'Crushed and Broken Granite',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1429',
    description: 'Crushed and Broken Stone, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1442',
    description: 'Construction Sand and Gravel',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1446',
    description: 'Industrial Sand',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1455',
    description: 'Kaolin and Ball Clay',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1459',
    description:
      'Clay, Ceramic, and Refractory Minerals, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1474',
    description: 'Potash, Soda, and Borate Minerals',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1475',
    description: 'Phosphate Rock',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1479',
    description:
      'Chemical and Fertilizer Mineral Mining, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1481',
    description: 'Nonmetallic Minerals Services, Except Fuels',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1499',
    description: 'Miscellaneous Nonmetallic Minerals, Except Fuels',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1521',
    description: 'General Contractors-Single-Family Houses',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1522',
    description:
      'General Contractors-Residential Buildings, Other Than Single-Family',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1531',
    description: 'Operative Builders',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1541',
    description: 'General Contractors-Industrial Buildings and Warehouses',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1542',
    description:
      'General Contractors-Nonresidential Buildings, Other than Industrial Buildings and Warehouses',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1611',
    description: 'Highway and Street Construction, Except Elevated Highways',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1622',
    description: 'Bridge, Tunnel, and Elevated Highway Construction',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1623',
    description:
      'Water, Sewer, Pipeline, and Communications and Power Line Construction',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1629',
    description: 'Heavy Construction, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1711',
    description: 'Plumbing, Heating and Air-Conditioning',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1721',
    description: 'Painting and Paper Hanging',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1731',
    description: 'Electrical Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1741',
    description: 'Masonry, Stone Setting, and Other Stone Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1742',
    description: 'Plastering, Drywall, Acoustical, and Insulation Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1743',
    description: 'Terrazzo, Tile, Marble, and Mosaic Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1751',
    description: 'Carpentry Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1752',
    description: 'Floor Laying and Other Floor Work, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1761',
    description: 'Roofing, Siding, and Sheet Metal Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1771',
    description: 'Concrete Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1781',
    description: 'Water Well Drilling',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1791',
    description: 'Structural Steel Erection',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1793',
    description: 'Glass and Glazing Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1794',
    description: 'Excavation Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1795',
    description: 'Wrecking and Demolition Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1796',
    description:
      'Installation or Erection of Building Equipment, Not Elsewhere',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '1799',
    description: 'Special Trade Contractors, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2011',
    description: 'Meat Packing Plants',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2013',
    description: 'Sausages and Other Prepared Meat Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2015',
    description: 'Poultry Slaughtering and Processing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2021',
    description: 'Creamery Butter',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2022',
    description: 'Natural, Processed, and Imitation Cheese',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2023',
    description: 'Dry, Condensed, and Evaporated Dairy Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2024',
    description: 'Ice Cream and Frozen Desserts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2026',
    description: 'Fluid Milk',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2032',
    description: 'Canned Specialties',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2033',
    description: 'Canned Fruits, Vegetables, Preserves, Jams, and Jellies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2034',
    description: 'Dried and Dehydrated Fruits, Vegetables, and Soup Mixes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2035',
    description:
      'Pickled Fruits and Vegetables, Vegetable Sauces and Seasonings, and Salad Dressings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2037',
    description: 'Frozen Fruits, Fruit Juices, and Vegetables',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2038',
    description: 'Frozen Specialties, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2041',
    description: 'Flour and Other Grain Mill Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2043',
    description: 'Cereal Breakfast Foods',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2044',
    description: 'Rice Milling',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2045',
    description: 'Prepared Flour Mixes and Doughs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2046',
    description: 'Wet Corn Milling',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2047',
    description: 'Dog and Cat Food',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2048',
    description:
      'Prepared Feed and Feed Ingredients for Animals and Fowls, Except Dogs and Cats',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2051',
    description: 'Bread and Other Bakery Products, Except Cookies and Crackers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2052',
    description: 'Cookies and Crackers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2053',
    description: 'Frozen Bakery Products, Except Bread',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2061',
    description: 'Cane Sugar, Except Refining',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2062',
    description: 'Cane Sugar Refining',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2063',
    description: 'Beet Sugar',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2064',
    description: 'Candy and Other Confectionery Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2066',
    description: 'Chocolate and Cocoa Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2067',
    description: 'Chewing Gum',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2068',
    description: 'Salted and Roasted Nuts and Seeds',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2074',
    description: 'Cottonseed Oil Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2075',
    description: 'Soybean Oil Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2076',
    description: 'Vegetable Oil Mills, Except Corn, Cottonseed, and Soybean',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2077',
    description: 'Animal and Marine Fats and Oils',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2079',
    description:
      'Shortening, Table Oils, Margarine, and Other Edible Fats and Oils, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2082',
    description: 'Malt Beverages',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2083',
    description: 'Malt',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2084',
    description: 'Wines, Brandy, and Brandy Spirits',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2085',
    description: 'Distilled and Blended Liquors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2086',
    description: 'Bottled and Canned Soft Drinks and Carbonated Waters',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2087',
    description:
      'Flavoring Extracts and Flavoring Syrups, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2091',
    description: 'Canned and Cured Fish and Seafoods',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2092',
    description: 'Prepared Fresh or Frozen Fish and Seafoods',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2095',
    description: 'Roasted Coffee',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2096',
    description: 'Potato Chips, Corn Chips, and Similar Snacks',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2097',
    description: 'Manufactured Ice',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2098',
    description: 'Macaroni, Spaghetti, Vermicelli, and Noodles',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2099',
    description: 'Food Preparations, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2111',
    description: 'Cigarettes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2121',
    description: 'Cigars',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2131',
    description: 'Chewing and Smoking Tobacco and Snuff',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2141',
    description: 'Tobacco Stemming and Redrying',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2211',
    description: 'Broadwoven Fabric Mills, Cotton',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2221',
    description: 'Broadwoven Fabric Mills, Manmade Fiber and Silk',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2231',
    description:
      'Broadwoven Fabric Mills, Wool (Including Dyeing and Finishing)',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2241',
    description:
      'Narrow Fabric and Other Smallware Mills: Cotton, Wool, Silk, and Manmade Fiber',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2251',
    description: "Women's Full-Length and Knee-Length Hosiery, Except Socks",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2252',
    description: 'Hosiery, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2253',
    description: 'Knit Outerwear Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2254',
    description: 'Knit Underwear and Nightwear Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2257',
    description: 'Weft Knit Fabric Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2258',
    description: 'Lace and Warp Knit Fabric Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2259',
    description: 'Knitting Mills, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2261',
    description: 'Finishers of Broadwoven Fabrics of Cotton',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2262',
    description: 'Finishers of Broadwoven Fabrics of Manmade Fiber and Silk',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2269',
    description: 'Finishers of Textiles, Not elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2273',
    description: 'Carpets and Rugs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2281',
    description: 'Yarn Spinning Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2282',
    description: 'Yarn Texturizing, Throwing, Twisting, and Winding Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2284',
    description: 'Thread Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2295',
    description: 'Coated Fabrics, Not Rubberized',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2296',
    description: 'Tire Cord and Fabrics',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2297',
    description: 'Non-woven Fabrics',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2298',
    description: 'Cordage and Twine',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2299',
    description: 'Textile goods, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2311',
    description: "Men's and Boys' Suits, Coats, and Overcoats",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2321',
    description: "Men's and Boys' Shirts, Except Work Shirts",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2322',
    description: "Men's and Boys' Underwear and Nightwear",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2323',
    description: "Men's and Boys' Neckwear",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2325',
    description: "Men's and Boys' Separate Trousers and Slacks",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2326',
    description: "Men's and Boys' Work Clothing",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2329',
    description: "Men's and Boys' Clothing, Not Elsewhere Classified",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2331',
    description: "Women's, Misses', and Juniors' Blouses and Shirts",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2335',
    description: "Women's, Misses', and Juniors' Dresses",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2337',
    description: "Women's, Misses', and Juniors' Suits, Skirts, and Coats",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2339',
    description:
      "Women's, Misses', and Juniors' Outerwear, Not Elsewhere Classified",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2341',
    description:
      "Women's, Misses', Children's, and Infants' Underwear and Nightwear",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2342',
    description: 'Brassieres, Girdles, and Allied Garments',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2353',
    description: 'Hats, Caps, and Millinery',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2361',
    description:
      "Girls', Children's, and Infants' Dresses, Blouses, and Shirts",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2369',
    description:
      "Girls', Children's, and Infants' Outerwear, Not Elsewhere Classified",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2371',
    description: 'Fur Goods',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2381',
    description: 'Dress and Work Gloves, Except Knit and All-Leather',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2384',
    description: 'Robes and Dressing Gowns',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2385',
    description: 'Waterproof Outerwear',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2386',
    description: 'Leather and Sheep-Lined Clothing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2387',
    description: 'Apparel belts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2389',
    description: 'Apparel and Accessories, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2391',
    description: 'Curtains and Draperies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2392',
    description: 'House furnishing, Except Curtains and Draperies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2393',
    description: 'Textile Bags',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2394',
    description: 'Canvas and Related Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2395',
    description:
      'Pleating, Decorative and Novelty Stitching, and Tucking for the Trade',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2396',
    description: 'Automotive Trimmings, Apparel Findings, and Related Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2397',
    description: 'Schiffli Machine Embroideries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2399',
    description: 'Fabricated Textile Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2411',
    description: 'Logging',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2421',
    description: 'Sawmills and Planing Mills, General',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2426',
    description: 'Hardwood Dimension and Flooring Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2429',
    description: 'Special Product Sawmills, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2431',
    description: 'Millwork',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2434',
    description: 'Wood Kitchen Cabinets',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2435',
    description: 'Hardwood Veneer and Plywood',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2436',
    description: 'Softwood Veneer and Plywood',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2439',
    description: 'Structural Wood Members, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2441',
    description: 'Nailed and Lock Corner Wood Boxes and Shook',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2448',
    description: 'Wood Pallets and Skids',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2449',
    description: 'Wood Containers, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2451',
    description: 'Mobile Homes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2452',
    description: 'Prefabricated Wood Buildings and Components',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2491',
    description: 'Wood Preserving',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2493',
    description: 'Reconstituted Wood Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2499',
    description: 'Wood Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2511',
    description: 'Wood Household Furniture, Except Upholstered',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2512',
    description: 'Wood Household Furniture, Upholstered',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2514',
    description: 'Metal Household Furniture',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2515',
    description: 'Mattresses, Foundations, and Convertible Beds',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2517',
    description:
      'Wood Television, Radio, Phonograph, and Sewing Machine Cabinets',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2519',
    description: 'Household Furniture, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2521',
    description: 'Wood Office Furniture',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2522',
    description: 'Office Furniture, Except Wood',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2531',
    description: 'Public Building and Related Furniture',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2541',
    description:
      'Wood Office and Store Fixtures, Partitions, Shelving, and Lockers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2542',
    description:
      'Office and Store Fixtures, Partitions, Shelving, and Lockers, Except Wood',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2591',
    description: 'Drapery Hardware and Window Blinds and Shades',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2599',
    description: 'Furniture and Fixtures, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2611',
    description: 'Pulp Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2621',
    description: 'Paper Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2631',
    description: 'Paperboard Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2652',
    description: 'Setup Paperboard Boxes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2653',
    description: 'Corrugated and Solid Fiber Boxes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2655',
    description: 'Fiber Cans, Tubes, Drums, and Similar Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2656',
    description: 'Sanitary Food Containers, Except Folding',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2657',
    description: 'Folding Paperboard Boxes, Including Sanitary',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2671',
    description: 'Packaging Paper and Plastics Film, Coated and Laminated',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2672',
    description: 'Coated and Laminated Paper, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2673',
    description: 'Plastics, Foil, and Coated Paper Bags',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2674',
    description: 'Uncoated Paper and Multiwall Bags',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2675',
    description: 'Die-Cut Paper and Paperboard and Cardboard',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2676',
    description: 'Sanitary Paper Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2677',
    description: 'Envelopes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2678',
    description: 'Stationery, Tablets, and Related Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2679',
    description:
      'Converted Paper and Paperboard Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2711',
    description: 'Newspapers: Publishing, or Publishing and Printing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2721',
    description: 'Periodicals: Publishing, or Publishing and Printing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2731',
    description: 'Books: Publishing, or Publishing and Printing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2732',
    description: 'Book Printing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2741',
    description: 'Miscellaneous Publishing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2752',
    description: 'Commercial Printing, Lithographic',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2754',
    description: 'Commercial Printing, Gravure',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2759',
    description: 'Commercial Printing, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2761',
    description: 'Manifold Business Forms',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2771',
    description: 'Greeting Cards',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2782',
    description: 'Blankbooks, Looseleaf Binders and Devices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2789',
    description: 'Bookbinding and Related Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2791',
    description: 'Typesetting',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2796',
    description: 'Platemaking and Related Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2812',
    description: 'Alkalies and Chlorine',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2813',
    description: 'Industrial Gases',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2816',
    description: 'Inorganic Pigments',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2819',
    description: 'Industrial Inorganic Chemicals, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2821',
    description:
      'Plastics Materials, Synthetic Resins, and Nonvulcanizable Elastomers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2822',
    description: 'Synthetic Rubber (Vulcanizable Elastomers)',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2823',
    description: 'Cellulosic Manmade Fibers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2824',
    description: 'Manmade Organic Fibers, Except Cellulosic',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2833',
    description: 'Medicinal Chemicals and Botanical Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2834',
    description: 'Pharmaceutical Preparations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2835',
    description: 'In Vitro and In Vivo Diagnostic Substances',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2836',
    description: 'Biological Products, Except Diagnostic Substances',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2841',
    description: 'Soap and Other Detergents, Except Specialty Cleaners',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2842',
    description: 'Specialty Cleaning, Polishing, and Sanitation Preparations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2843',
    description:
      'Surface Active Agents, Finishing Agents, Sulfonated Oils, and Assistants',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2844',
    description: 'Perfumes, Cosmetics, and Other Toilet Preparations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2851',
    description: 'Paints, Varnishes, Lacquers, Enamels, and Allied Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2861',
    description: 'Gum and Wood Chemicals',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2865',
    description:
      'Cyclic Organic Crudes and Intermediates, and Organic Dyes and Pigments',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2869',
    description: 'Industrial Organic Chemicals, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2873',
    description: 'Nitrogenous Fertilizers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2874',
    description: 'Phosphatic Fertilizers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2875',
    description: 'Fertilizers, Mixing Only',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2879',
    description:
      'Pesticides and Agricultural Chemicals, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2891',
    description: 'Adhesives and Sealants',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2892',
    description: 'Explosives',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2893',
    description: 'Printing Ink',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2895',
    description: 'Carbon Black',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2899',
    description:
      'Chemicals and Chemical Preparations, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2911',
    description: 'Petroleum Refining',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2951',
    description: 'Asphalt Paving Mixtures and Blocks',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2952',
    description: 'Asphalt Felts and Coatings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2992',
    description: 'Lubricating Oils and Greases',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '2999',
    description: 'Products of Petroleum and Coal, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3011',
    description: 'Tires and Inner Tubes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3021',
    description: 'Rubber and Plastics Footwear',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3052',
    description: 'Rubber and Plastics Hose and Belting',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3053',
    description: 'Gaskets, Packing, and Sealing Devices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3061',
    description: 'Molded, Extruded, and Lathe-Cut Mechanical Rubber Goods',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3069',
    description: 'Fabricated Rubber Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3081',
    description: 'Unsupported Plastics Film and Sheet',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3082',
    description: 'Unsupported Plastics Profile Shapes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3083',
    description: 'Laminated Plastics Plate, Sheet, and Profile Shapes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3084',
    description: 'Plastics Pipe',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3085',
    description: 'Plastics Bottles',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3086',
    description: 'Plastics Foam Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3087',
    description: 'Custom Compounding of Purchased Plastics Resins',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3088',
    description: 'Plastics Plumbing Fixtures',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3089',
    description: 'Plastics Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3111',
    description: 'Leather Tanning and Finishing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3131',
    description: 'Boot and Shoe Cut Stock and Findings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3142',
    description: 'House Slippers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3143',
    description: "Men's Footwear, Except Athletic",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3144',
    description: "Women's Footwear, Except Athletic",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3149',
    description: 'Footwear, Except Rubber, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3151',
    description: 'Leather Gloves and Mittens',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3161',
    description: 'Luggage',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3171',
    description: "Women's Handbags and Purses",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3172',
    description: "Personal Leather Goods, Except Women's Handbags and Purses",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3199',
    description: 'Leather Goods, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3211',
    description: 'Flat Glass',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3221',
    description: 'Glass Containers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3229',
    description:
      'Pressed and Blown Glass and Glassware, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3231',
    description: 'Glass Products, Made of Purchased Glass',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3241',
    description: 'Cement, Hydraulic',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3251',
    description: 'Brick and Structural Clay Tile',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3253',
    description: 'Ceramic Wall and Floor Tile',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3255',
    description: 'Clay Refractories',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3259',
    description: 'Structural Clay Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3261',
    description:
      'Vitreous China Plumbing Fixtures and China and Earthenware Fittings and Bathroom Accessories',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3262',
    description: 'Vitreous China Table and Kitchen Articles',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3263',
    description: 'Fine Earthenware (Whiteware) Table and Kitchen Articles',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3264',
    description: 'Porcelain Electrical Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3269',
    description: 'Pottery Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3271',
    description: 'Concrete Block and Brick',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3272',
    description: 'Concrete Products, Except Block and Brick',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3273',
    description: 'Ready-Mixed Concrete',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3274',
    description: 'Lime',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3275',
    description: 'Gypsum Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3281',
    description: 'Cut Stone and Stone Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3291',
    description: 'Abrasive Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3292',
    description: 'Asbestos Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3295',
    description: 'Minerals and Earths, Ground or Otherwise Treated',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3296',
    description: 'Mineral Wool',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3297',
    description: 'Nonclay Refractories',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3299',
    description: 'Nonmetallic Mineral Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3312',
    description:
      'Steel Works, Blast Furnaces (Including Coke Ovens), and Rolling Mills',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3313',
    description: 'Electrometallurgical Products, Except Steel',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3315',
    description: 'Steel Wiredrawing and Steel Nails and Spikes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3316',
    description: 'Cold-Rolled Steel Sheet, Strip, and Bars',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3317',
    description: 'Steel Pipe and Tubes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3321',
    description: 'Gray and Ductile Iron Foundries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3322',
    description: 'Malleable Iron Foundries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3324',
    description: 'Steel Investment Foundries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3325',
    description: 'Steel Foundries, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3331',
    description: 'Primary Smelting and Refining of Copper',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3334',
    description: 'Primary Production of Aluminum',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3339',
    description:
      'Primary Smelting and Refining of Nonferrous Metals, Except Copper and Aluminum',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3341',
    description: 'Secondary Smelting and Refining of Nonferrous Metals',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3351',
    description: 'Rolling, Drawing, and Extruding Of Copper',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3353',
    description: 'Aluminum Sheet, Plate, and Foil',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3354',
    description: 'Aluminum Extruded Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3355',
    description: 'Aluminum Rolling and Drawing, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3356',
    description:
      'Rolling, Drawing, and Extruding of Nonferrous Metals, Except Copper and Aluminum',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3357',
    description: 'Drawing and Insulating of Nonferrous Wire',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3363',
    description: 'Aluminum Die-Castings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3364',
    description: 'Nonferrous Die-Castings, Except Aluminum',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3365',
    description: 'Aluminum Foundries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3366',
    description: 'Copper Foundries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3369',
    description: 'Nonferrous Foundries, Except Aluminum and Copper',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3398',
    description: 'Metal Heat Treating',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3399',
    description: 'Primary Metal Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3411',
    description: 'Metal Cans',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3412',
    description: 'Metal Shipping Barrels, Drums, Kegs, and Pails',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3421',
    description: 'Cutlery',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3423',
    description: 'Hand and Edge Tools, Except Machine Tools and Handsaws',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3425',
    description: 'Saw Blades and Handsaws',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3429',
    description: 'Hardware, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3431',
    description: 'Enameled Iron and Metal Sanitary Ware',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3432',
    description: 'Plumbing Fixture Fittings and Trim',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3433',
    description: 'Heating Equipment, Except Electric and Warm Air Furnaces',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3441',
    description: 'Fabricated Structural Metal',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3442',
    description: 'Metal Doors, Sash, Frames, Molding, and Trim Manufacturing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3443',
    description: 'Fabricated Plate Work (Boiler Shops)',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3444',
    description: 'Sheet Metal Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3446',
    description: 'Architectural and Ornamental Metal Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3448',
    description: 'Prefabricated Metal Buildings and Components',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3449',
    description: 'Miscellaneous Structural Metal Work',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3451',
    description: 'Screw Machine Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3452',
    description: 'Bolts, Nuts, Screws, Rivets, and Washers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3462',
    description: 'Iron and Steel Forgings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3463',
    description: 'Nonferrous Forgings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3465',
    description: 'Automotive Stampings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3466',
    description: 'Crowns and Closures',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3469',
    description: 'Metal Stampings, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3471',
    description: 'Electroplating, Plating, Polishing, Anodizing, and Coloring',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3479',
    description:
      'Coating, Engraving, and Allied Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3482',
    description: 'Small Arms Ammunition',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3483',
    description: 'Ammunition, Except for Small Arms',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3484',
    description: 'Small Arms',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3489',
    description: 'Ordnance and Accessories, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3491',
    description: 'Industrial Valves',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3492',
    description: 'Fluid Power Valves and Hose Fittings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3493',
    description: 'Steel Springs, Except Wire',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3494',
    description: 'Valves and Pipe Fittings, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3495',
    description: 'Wire Springs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3496',
    description: 'Miscellaneous Fabricated Wire Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3497',
    description: 'Metal Foil and Leaf',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3498',
    description: 'Fabricated Pipe and Pipe Fittings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3499',
    description: 'Fabricated Metal Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3511',
    description:
      'Steam, Gas, and Hydraulic Turbines, and Turbine Generator Set Units',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3519',
    description: 'Internal Combustion Engines, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3523',
    description: 'Farm Machinery and Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3524',
    description: 'Lawn and Garden Tractors and Home Lawn and Garden Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3531',
    description: 'Construction Machinery and Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3532',
    description:
      'Mining Machinery and Equipment, Except Oil and Gas Field Machinery and Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3533',
    description: 'Oil and Gas Field Machinery and Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3534',
    description: 'Elevators and Moving Stairways',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3535',
    description: 'Conveyors and Conveying Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3536',
    description: 'Overhead Traveling Cranes, Hoists, and Monorail Systems',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3537',
    description: 'Industrial Trucks, Tractors, Trailers, and Stackers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3541',
    description: 'Machine Tools, Metal Cutting Types',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3542',
    description: 'Machine Tools, Metal Forming Types',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3543',
    description: 'Industrial Patterns',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3544',
    description:
      'Special Dies and Tools, Die Sets, Jigs and Fixtures, and Industrial Molds',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3545',
    description:
      "Cutting Tools, Machine Tool Accessories, and Machinists' Precision Measuring Devices",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3546',
    description: 'Power-Driven Handtools',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3547',
    description: 'Rolling Mill Machinery and Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3548',
    description: 'Electric and Gas Welding and Soldering Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3549',
    description: 'Metalworking Machinery, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3552',
    description: 'Textile Machinery',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3553',
    description: 'Woodworking Machinery',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3554',
    description: 'Paper Industries Machinery',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3555',
    description: 'Printing Trades Machinery and Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3556',
    description: 'Food Products Machinery',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3559',
    description: 'Special Industry Machinery, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3561',
    description: 'Pumps and Pumping Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3562',
    description: 'Ball and Roller Bearings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3563',
    description: 'Air and Gas Compressors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3564',
    description:
      'Industrial and Commercial Fans and Blowers and Air Purification Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3565',
    description: 'Packaging Machinery',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3566',
    description: 'Speed Changers, Industrial High-Speed Drives, and Gears',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3567',
    description: 'Industrial Process Furnaces and Ovens',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3568',
    description:
      'Mechanical Power Transmission Equipment, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3569',
    description: 'General Industrial Machinery and Equipment, Not Elsewhere',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3571',
    description: 'Electronic Computers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3572',
    description: 'Computer Storage Devices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3575',
    description: 'Computer Terminals',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3577',
    description: 'Computer Peripheral Equipment, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3578',
    description:
      'Calculating and Accounting Machines, Except Electronic Computers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3579',
    description: 'Office Machines, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3581',
    description: 'Automatic Vending Machines',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3582',
    description: 'Commercial Laundry, Drycleaning, and Pressing Machines',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3585',
    description:
      'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3586',
    description: 'Measuring and Dispensing Pumps',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3589',
    description: 'Service Industry Machinery, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3592',
    description: 'Carburetors, Pistons, Piston Rings, and Valves',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3593',
    description: 'Fluid Power Cylinders and Actuators',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3594',
    description: 'Fluid Power Pumps and Motors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3596',
    description: 'Scales and Balances, Except Laboratory',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3599',
    description:
      'Industrial and Commercial Machinery and Equipment, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3612',
    description: 'Power, Distribution, and Specialty Transformers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3613',
    description: 'Switchgear and Switchboard Apparatus',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3621',
    description: 'Motors and Generators',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3624',
    description: 'Carbon and Graphite Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3625',
    description: 'Relays and Industrial Controls',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3629',
    description: 'Electrical Industrial Apparatus, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3631',
    description: 'Household Cooking Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3632',
    description: 'Household Refrigerators and HOme and Farm Freezers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3633',
    description: 'Household Laundry Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3634',
    description: 'Electric Housewares and Fans',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3635',
    description: 'Household Vacuum Cleaners',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3639',
    description: 'Household Appliances, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3641',
    description: 'Electric Lamp Bulbs and Tubes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3643',
    description: 'Current-Carrying Wiring Devices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3644',
    description: 'Noncurrent-Carrying Wiring Devices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3645',
    description: 'Residential Electric Lighting Fixtures',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3646',
    description:
      'Commercial, Industrial, and Institutional Electric Lighting Fixtures',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3647',
    description: 'Vehicular Lighting Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3648',
    description: 'Lighting Equipment, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3651',
    description: 'Household Audio and Video Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3652',
    description: 'Phonograph Records and Prerecorded Audio Tapes and Disks',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3661',
    description: 'Telephone and Telegraph Apparatus',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3663',
    description:
      'Radio and Television Broadcasting and Communications Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3669',
    description: 'Communications Equipment, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3671',
    description: 'Electron Tubes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3672',
    description: 'Printed Circuit Boards',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3674',
    description: 'Semiconductors and Related Devices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3675',
    description: 'Electronic Capacitors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3676',
    description: 'Electronic Resistors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3677',
    description: 'Electronic Coils, Transformers, and Other Inductors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3678',
    description: 'Electronic Connectors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3679',
    description: 'Electronic Components, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3691',
    description: 'Storage Batteries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3692',
    description: 'Primary Batteries, Dry and Wet',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3694',
    description: 'Electrical Equipment for Internal Combustion Engines',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3695',
    description: 'Magnetic And Optical Recording Media',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3699',
    description: 'Electrical Machinery, Equipment, and Supplies, Not Elsewhere',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3711',
    description: 'Motor Vehicles and Passenger Car Bodies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3713',
    description: 'Truck and Bus Bodies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3714',
    description: 'Motor Vehicle Parts and Accessories',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3715',
    description: 'Truck Trailers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3716',
    description: 'Motor Homes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3721',
    description: 'Aircraft',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3724',
    description: 'Aircraft Engines and Engine Parts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3728',
    description:
      'Aircraft Parts and Auxiliary Equipment, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3731',
    description: 'Ship Building and Repairing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3732',
    description: 'Boat Building and Repairing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3743',
    description: 'Railroad Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3751',
    description: 'Motorcycles, Bicycles, and Parts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3761',
    description: 'Guided Missiles and Space Vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3764',
    description:
      'Guided Missile and Space Vehicle Propulsion Units and Propulsion Unit Parts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3769',
    description:
      'Guided Missile Space Vehicle Parts and Auxiliary Equipment, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3792',
    description: 'Travel Trailers and Campers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3795',
    description: 'Tanks and Tank Components',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3799',
    description: 'Transportation Equipment, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3812',
    description:
      'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical Systems and Instruments',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3821',
    description: 'Laboratory Apparatus and Furniture',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3822',
    description:
      'Automatic Controls for Regulating Residential and Commercial Environments and Appliances',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3823',
    description:
      'Industrial Instruments for Measurement, Display, and Control of Process Variables; and Related Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3824',
    description: 'Totalizing Fluid Meters and Counting Devices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3825',
    description:
      'Instruments for Measuring and Testing of Electricity and Electrical Signals',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3826',
    description: 'Laboratory Analytical Instruments',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3827',
    description: 'Optical Instruments and Lenses',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3829',
    description: 'Measuring and Controlling Devices, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3841',
    description: 'Surgical and Medical Instruments and Apparatus',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3842',
    description: 'Orthopedic, Prosthetic, and Surgical Appliances and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3843',
    description: 'Dental Equipment and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3844',
    description: 'X-Ray Apparatus and Tubes and Related Irradiation Apparatus',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3845',
    description: 'Electromedical and Electrotherapeutic Apparatus',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3851',
    description: 'Ophthalmic Goods',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3861',
    description: 'Photographic Equipment and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3873',
    description: 'Watches, Clocks, Clockwork Operated Devices, and Parts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3911',
    description: 'Jewelry, Precious Metal',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3914',
    description: 'Silverware, Plated Ware, and Stainless Steel Ware',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3915',
    description: "Jewelers' Findings and Materials, and Lapidary Work",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3931',
    description: 'Musical Instruments',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3942',
    description: 'Dolls and Stuffed Toys',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3944',
    description:
      "Games, Toys, and Children's Vehicles, Except Dolls and Bicycles",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3949',
    description: 'Sporting and Athletic Goods, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3951',
    description: 'Pens, Mechanical Pencils, and Parts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3952',
    description: "Lead Pencils, Crayons, and Artists' Materials",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3953',
    description: 'Marking Devices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3955',
    description: 'Carbon Paper and Inked Ribbons',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3961',
    description: 'Costume Jewelry and Costume Novelties, Except Precious Metal',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3965',
    description: 'Fasteners, Buttons, Needles, and Pins',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3991',
    description: 'Brooms and Brushes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3993',
    description: 'Signs and Advertising Specialties',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3995',
    description: 'Burial Caskets',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3996',
    description:
      'Linoleum, Asphalted-Felt-Base, and Other Hard Surface Floor Coverings, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '3999',
    description: 'Manufacturing Industries, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4011',
    description: 'Railroads, Line-Haul Operating',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4013',
    description: 'Railroad Switching and Terminal Establishments',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4111',
    description: 'Local and Suburban Transit',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4119',
    description: 'Local Passenger Transportation, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4121',
    description: 'Taxicabs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4131',
    description: 'Intercity and Rural Bus Transportation',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4141',
    description: 'Local Bus Charter Service',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4142',
    description: 'Bus Charter Service, Except Local',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4151',
    description: 'School Buses',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4173',
    description:
      'Terminal and Service Facilities for Motor Vehicle Passenger Transportation',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4212',
    description: 'Local Trucking Without Storage',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4213',
    description: 'Trucking, Except Local',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4214',
    description: 'Local Trucking With Storage',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4215',
    description: 'Courier Services, Except by Air',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4221',
    description: 'Farm Product Warehousing and Storage',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4222',
    description: 'Refrigerated Warehousing and Storage',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4225',
    description: 'General Warehousing and Storage',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4226',
    description: 'Special Warehousing and Storage, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4231',
    description:
      'Terminal and Joint Terminal Maintenance Facilities for Motor Freight Transportation',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4311',
    description: 'United States Postal Service',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4412',
    description: 'Deep Sea Foreign Transportation of Freight',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4424',
    description: 'Deep Sea Domestic Transportation of Freight',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4432',
    description:
      'Freight Transportation on the Great Lakes-St. Lawrence Seaway',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4449',
    description: 'Water Transportation of Freight, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4481',
    description: 'Deep Sea Transportation of Passengers, Except by Ferry',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4482',
    description: 'Ferries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4489',
    description: 'Water Transportation of Passengers, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4491',
    description: 'Marine Cargo Handling',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4492',
    description: 'Towing and Tugboat Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4493',
    description: 'Marinas',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4499',
    description: 'Water Transportation Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4512',
    description: 'Air Transportation, Scheduled',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4513',
    description: 'Air Courier Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4522',
    description: 'Air Transportation, Nonscheduled',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4581',
    description: 'Airports, Flying Fields, and Airport Terminal Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4612',
    description: 'Crude Petroleum Pipelines',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4613',
    description: 'Refined Petroleum Pipelines',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4619',
    description: 'Pipelines, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4724',
    description: 'Travel Agencies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4725',
    description: 'Tour Operators',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4729',
    description:
      'Arrangement of Passenger Transportation, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4731',
    description: 'Arrangement of Transportation of Freight and Cargo',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4741',
    description: 'Rental of Railroad Cars',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4783',
    description: 'Packing and Crating',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4785',
    description:
      'Fixed Facilities and Inspection and Weighing Services for Motor Vehicle Transportation',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4789',
    description: 'Transportation Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4812',
    description: 'Radiotelephone Communications',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4813',
    description: 'Telephone Communications, Except Radiotelephone',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4822',
    description: 'Telegraph and Other Message Communications',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4832',
    description: 'Radio Broadcasting Stations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4833',
    description: 'Television Broadcasting Stations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4841',
    description: 'Cable and Other Pay Television Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4899',
    description: 'Communications Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4911',
    description: 'Electric Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4922',
    description: 'Natural Gas Transmission',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4923',
    description: 'Natural Gas Transmission and Distribution',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4924',
    description: 'Natural Gas Distribution',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4925',
    description:
      'Mixed, Manufactured, or Liquefied Petroleum Gas Production and/or',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4931',
    description: 'Electric and Other Services Combined',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4932',
    description: 'Gas and Other Services Combined',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4939',
    description: 'Combination Utilities, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4941',
    description: 'Water Supply',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4952',
    description: 'Sewerage Systems',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4953',
    description: 'Refuse Systems',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4959',
    description: 'Sanitary Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4961',
    description: 'Steam and Air-Conditioning Supply',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '4971',
    description: 'Irrigation Systems',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5012',
    description: 'Automobiles and Other Motor Vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5013',
    description: 'Motor Vehicle Supplies and New Parts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5014',
    description: 'Tires and Tubes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5015',
    description: 'Motor Vehicle Parts, Used',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5021',
    description: 'Furniture',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5023',
    description: 'Home furnishings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5031',
    description: 'Lumber, Plywood, Millwork, and Wood Panels',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5032',
    description: 'Brick, Stone, and Related Construction Materials',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5033',
    description: 'Roofing, Siding, and Insulation Materials',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5039',
    description: 'Construction Materials, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5043',
    description: 'Photographic Equipment and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5044',
    description: 'Office Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5045',
    description: 'Computers and Computer Peripheral Equipment and Software',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5046',
    description: 'Commercial Equipment, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5047',
    description: 'Medical, Dental, and Hospital Equipment and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5048',
    description: 'Ophthalmic Goods',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5049',
    description:
      'Professional Equipment and Supplies, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5051',
    description: 'Metals Service Centers and Offices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5052',
    description: 'Coal and Other Minerals and Ores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5063',
    description:
      'Electrical Apparatus and Equipment Wiring Supplies, and Construction Materials',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5064',
    description: 'Electrical Appliances, Television and Radio Sets',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5065',
    description: 'Electronic Parts and Equipment, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5072',
    description: 'Hardware',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5074',
    description: 'Plumbing and Heating Equipment and Supplies (Hydronics)',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5075',
    description: 'Warm Air Heating and Air-Conditioning Equipment and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5078',
    description: 'Refrigeration Equipment and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5082',
    description:
      'Construction and Mining (Except Petroleum) Machinery and Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5083',
    description: 'Farm and Garden Machinery and Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5084',
    description: 'Industrial Machinery and Equipment',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5085',
    description: 'Industrial Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5087',
    description: 'Service Establishment Equipment and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5088',
    description: 'Transportation Equipment and Supplies, Except Motor Vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5091',
    description: 'Sporting and Recreational Goods and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5092',
    description: 'Toys and Hobby Goods and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5093',
    description: 'Scrap and Waste Materials',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5094',
    description: 'Jewelry, Watches, Precious Stones, and Precious Metals',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5099',
    description: 'Durable Goods, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5111',
    description: 'Printing and Writing Paper',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5112',
    description: 'Stationery and Office Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5113',
    description: 'Industrial and Personal Service Paper',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5122',
    description: "Drugs, Drug Proprietaries, and Druggists' Sundries",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5131',
    description: 'Piece Goods, Notions, and Other Dry Good',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5136',
    description: "Men's and Boy's Clothing and Furnishings",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5137',
    description: "Women's, Children's, and Infants' Clothing and Accessories",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5139',
    description: 'Footwear',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5141',
    description: 'Groceries, General Line',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5142',
    description: 'Packaged Frozen Foods',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5143',
    description: 'Dairy Products, Except Dried or Canned',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5144',
    description: 'Poultry and Poultry Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5145',
    description: 'Confectionery',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5146',
    description: 'Fish and Seafoods',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5147',
    description: 'Meats and Meat Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5148',
    description: 'Fresh Fruits and Vegetables',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5149',
    description: 'Groceries and Related Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5153',
    description: 'Grain and Field Beans',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5154',
    description: 'Livestock',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5159',
    description: 'Farm-Product Raw Materials, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5162',
    description: 'Plastics Materials and Basic Forms and Shapes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5169',
    description: 'Chemicals and Allied Products, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5171',
    description: 'Petroleum Bulk stations and Terminals',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5172',
    description:
      'Petroleum and Petroleum Products Wholesalers, Except Bulk Stations and Terminals',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5181',
    description: 'Beer and Ale',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5182',
    description: 'Wine and Distilled Alcoholic Beverages',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5191',
    description: 'Farm Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5192',
    description: 'Books, Periodicals, and Newspapers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5193',
    description: "Flowers, Nursery Stock, and Florists' Supplies",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5194',
    description: 'Tobacco and Tobacco Products',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5198',
    description: 'Paints, Varnishes, and Supplies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5199',
    description: 'Nondurable Goods, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5211',
    description: 'Lumber and Other Building Materials Dealers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5231',
    description: 'Paint, Glass, and Wallpaper Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5251',
    description: 'Hardware Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5261',
    description: 'Retail Nurseries, Lawn and Garden Supply Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5271',
    description: 'Mobile Home Dealers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5311',
    description: 'Department Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5331',
    description: 'Variety Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5399',
    description: 'Miscellaneous General Merchandise Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5411',
    description: 'Grocery Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5421',
    description:
      'Meat and Fish (Seafood) Markets, Including Freezer Provisioners',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5431',
    description: 'Fruit and Vegetable Markets',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5441',
    description: 'Candy, Nut, and Confectionery Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5451',
    description: 'Dairy Products Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5461',
    description: 'Retail Bakeries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5499',
    description: 'Miscellaneous Food Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5511',
    description: 'Motor Vehicle Dealers (New and Used)',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5521',
    description: 'Motor Vehicle Dealers (Used Only)',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5531',
    description: 'Auto and Home Supply Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5541',
    description: 'Gasoline Service Stations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5551',
    description: 'Boat Dealers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5561',
    description: 'Recreational Vehicle Dealers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5571',
    description: 'Motorcycle Dealers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5599',
    description: 'Automotive Dealers, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5611',
    description: "Men's and Boys' Clothing and Accessory Stores",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5621',
    description: "Women's Clothing Stores",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5632',
    description: "Women's Accessory and Specialty Stores",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5641',
    description: "Children's and Infants' Wear Stores",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5651',
    description: 'Family Clothing Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5661',
    description: 'Shoe Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5699',
    description: 'Miscellaneous Apparel and Accessory Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5712',
    description: 'Furniture Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5713',
    description: 'Floor Covering Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5714',
    description: 'Drapery, Curtain, and Upholstery Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5719',
    description: 'Miscellaneous home furnishings Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5722',
    description: 'Household Appliance Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5731',
    description: 'Radio, Television, and Consumer Electronics Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5734',
    description: 'Computer and Computer Software Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5735',
    description: 'Record and Prerecorded Tape Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5736',
    description: 'Musical Instrument Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5812',
    description: 'Eating Places',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5813',
    description: 'Drinking Places (alcoholic Beverages)',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5912',
    description: 'Drug Stores and Proprietary Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5921',
    description: 'Liquor Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5932',
    description: 'Used Merchandise Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5941',
    description: 'Sporting Goods Stores and Bicycle Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5942',
    description: 'Book Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5943',
    description: 'Stationery Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5944',
    description: 'Jewelry Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5945',
    description: 'Hobby, Toy, and Game Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5946',
    description: 'Camera and Photographic Supply Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5947',
    description: 'Gift, Novelty, and Souvenir Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5948',
    description: 'Luggage and Leather Goods Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5949',
    description: 'Sewing, Needlework, and Piece Goods Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5961',
    description: 'Catalog and Mail-Order Houses',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5962',
    description: 'Automatic Merchandising Machine Operators',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5963',
    description: 'Direct Selling Establishments',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5983',
    description: 'Fuel Oil Dealers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5984',
    description: 'Liquefied Petroleum Gas (Bottled Gas) Dealers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5989',
    description: 'Fuel Dealers, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5992',
    description: 'Florists',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5993',
    description: 'Tobacco Stores and Stands',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5994',
    description: 'News Dealers and Newsstands',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5995',
    description: 'Optical Goods Stores',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '5999',
    description: 'Miscellaneous Retail Stores, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6011',
    description: 'Federal Reserve Banks',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6019',
    description:
      'Central Reserve Depository Institutions, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6021',
    description: 'National Commercial Banks',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6022',
    description: 'State Commercial Banks',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6029',
    description: 'Commercial Banks, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6035',
    description: 'Savings Institutions, Federally Chartered',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6036',
    description: 'Savings Institutions, Not Federally Chartered',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6061',
    description: 'Credit Unions, Federally Chartered',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6062',
    description: 'Credit Unions, Not Federally Chartered',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6081',
    description: 'Branches and Agencies of Foreign Banks',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6082',
    description: 'Foreign Trade and International Banking Institutions',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6091',
    description: 'Non-deposit Trust Facilities,',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6099',
    description:
      'Functions Related to Depository Banking, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6111',
    description: 'Federal and Federally-Sponsored Credit Agencies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6141',
    description: 'Personal Credit Institutions',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6153',
    description: 'Short-Term Business Credit Institutions, Except Agricultural',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6159',
    description: 'Miscellaneous business Credit Institutions',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6162',
    description: 'Mortgage Bankers and Loan Correspondents',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6163',
    description: 'Loan Brokers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6211',
    description: 'Security Brokers, Dealers, and Flotation Companies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6221',
    description: 'Commodity Contracts Brokers and Dealers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6231',
    description: 'Security and Commodity Exchanges',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6282',
    description: 'Investment Advice',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6289',
    description:
      'Services Allied With the Exchange of Securities or Commodities, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6311',
    description: 'Life Insurance',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6321',
    description: 'Accident and Health Insurance',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6324',
    description: 'Hospital and Medical Service Plans',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6331',
    description: 'Fire, Marine, and Casualty Insurance',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6351',
    description: 'Surety Insurance',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6361',
    description: 'Title Insurance',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6371',
    description: 'Pension, Health, and Welfare Funds',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6399',
    description: 'Insurance Carriers, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6411',
    description: 'Insurance Agents, Brokers, and Service',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6512',
    description: 'Operators of Nonresidential Buildings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6513',
    description: 'Operators of Apartment Buildings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6514',
    description: 'Operators of Dwellings Other Than Apartment Buildings',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6515',
    description: 'Operators of Residential Mobile Home Sites',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6517',
    description: 'Lessors of Railroad Property',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6519',
    description: 'Lessors of Real Property, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6531',
    description: 'Real Estate Agents and Managers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6541',
    description: 'Title Abstract Offices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6552',
    description: 'Land Subdividers and Developers, Except Cemeteries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6553',
    description: 'Cemetery Subdividers and Developers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6712',
    description: 'Offices of Bank Holding Companies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6719',
    description: 'Offices of Holding Companies, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6722',
    description: 'Management Investment Offices, Open-End',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6726',
    description:
      'Unit Investment Trusts, Face-Amount Certificate Offices, and Closed-End Management Investment Offices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6732',
    description: 'Educational, Religious, and Charitable Trusts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6733',
    description: 'Trusts, Except Educational, Religious, and Charitable',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6792',
    description: 'Oil Royalty Traders',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6794',
    description: 'Patent Owners and Lessors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6798',
    description: 'Real Estate Investment Trusts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '6799',
    description: 'Investors, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7011',
    description: 'Hotels and Motels',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7021',
    description: 'Rooming and Boarding Houses',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7032',
    description: 'Sporting and Recreational Camps',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7033',
    description: 'Recreational Vehicle Parks and Campsites',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7041',
    description: 'Organization Hotels and Lodging Houses, on Membership Basis',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7211',
    description: 'Power Laundries, Family and Commercial',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7212',
    description: 'Garment Pressing, and Agents for Laundries and Drycleaners',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7213',
    description: 'Linen Supply',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7215',
    description: 'Coin-Operated Laundries and Drycleaning',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7216',
    description: 'Drycleaning Plants, Except Rug Cleaning',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7217',
    description: 'Carpet and Upholstery Cleaning',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7218',
    description: 'Industrial Launderers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7219',
    description: 'Laundry and Garment Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7221',
    description: 'Photographic Studios, Portrait',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7231',
    description: 'Beauty Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7241',
    description: 'Barber Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7251',
    description: 'Shoe Repair Shops and Shoeshine Parlors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7261',
    description: 'Funeral Service and Crematories',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7291',
    description: 'Tax Return Preparation Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7299',
    description: 'Miscellaneous Personal Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7311',
    description: 'Advertising Agencies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7312',
    description: 'Outdoor Advertising Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7313',
    description:
      "Radio, Television, and Publishers' Advertising Representatives",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7319',
    description: 'Advertising, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7322',
    description: 'Adjustment and Collection Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7323',
    description: 'Credit Reporting Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7331',
    description: 'Direct Mail Advertising Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7334',
    description: 'Photocopying and Duplicating Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7335',
    description: 'Commercial Photography',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7336',
    description: 'Commercial Art and Graphic Design',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7338',
    description: 'Secretarial and Court Reporting Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7342',
    description: 'Disinfecting and Pest Control Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7349',
    description: 'Building Cleaning and Maintenance Services, Not Elsewhere',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7352',
    description: 'Medical Equipment Rental and Leasing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7353',
    description: 'Heavy Construction Equipment Rental and Leasing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7359',
    description: 'Equipment Rental and Leasing, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7361',
    description: 'Employment Agencies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7363',
    description: 'Help Supply Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7371',
    description: 'Computer Programming Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7372',
    description: 'Prepackaged Software',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7373',
    description: 'Computer Integrated Systems Design',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7374',
    description:
      'Computer Processing and Data Preparation and Processing Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7375',
    description: 'Information Retrieval Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7376',
    description: 'Computer Facilities Management Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7377',
    description: 'Computer Rental and Leasing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7378',
    description: 'Computer Maintenance and Repair',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7379',
    description: 'Computer Related Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7381',
    description: 'Detective, Guard, and Armored Car Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7382',
    description: 'Security Systems Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7383',
    description: 'News Syndicates',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7384',
    description: 'Photofinishing Laboratories',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7389',
    description: 'Business Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7513',
    description: 'Truck Rental and Leasing, Without Drivers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7514',
    description: 'Passenger Car Rental',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7515',
    description: 'Passenger Car Leasing',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7519',
    description: 'Utility Trailer and Recreational Vehicle Rental',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7521',
    description: 'Automobile Parking',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7532',
    description: 'Top, Body, and Upholstery Repair Shops and Paint Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7533',
    description: 'Automotive Exhaust System Repair Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7534',
    description: 'Tire Retreading and Repair Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7536',
    description: 'Automotive Glass Replacement Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7537',
    description: 'Automotive Transmission Repair Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7538',
    description: 'General Automotive Repair Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7539',
    description: 'Automotive Repair Shops, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7542',
    description: 'Carwashes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7549',
    description: 'Automotive Services, Except Repair and Carwashes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7622',
    description: 'Radio and Television Repair Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7623',
    description: 'Refrigeration and Air-Conditioning Service and Repair Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7629',
    description:
      'Electrical and Electronic Repair Shops, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7631',
    description: 'Watch, Clock, and Jewelry Repair',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7641',
    description: 'Reupholstery and Furniture Repair',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7692',
    description: 'Welding Repair',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7694',
    description: 'Armature Rewinding Shops',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7699',
    description: 'Repair Shops and Related Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7812',
    description: 'Motion Picture and Video Tape Production',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7819',
    description: 'Services Allied to Motion Picture Production',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7822',
    description: 'Motion Picture and Video Tape Distribution',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7829',
    description: 'Services Allied to Motion Picture Distribution',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7832',
    description: 'Motion Picture Theaters, Except Drive-In',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7833',
    description: 'Drive-In Motion Picture Theaters',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7841',
    description: 'Video Tape Rental',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7911',
    description: 'Dance Studios, Schools, and Halls',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7922',
    description:
      'Theatrical Producers (Except Motion Picture) and Miscellaneous Theatrical Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7929',
    description:
      'Bands, Orchestras, Actors, and Other Entertainers and Entertainment Groups',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7933',
    description: 'Bowling Centers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7941',
    description: 'Professional Sports Clubs and Promoters',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7948',
    description: 'Racing, Including Track Operation',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7991',
    description: 'Physical Fitness Facilities',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7992',
    description: 'Public Golf Courses',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7993',
    description: 'Coin-Operated Amusement Devices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7996',
    description: 'Amusement Parks',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7997',
    description: 'Membership Sports and Recreation Clubs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '7999',
    description: 'Amusement and Recreation Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8011',
    description: 'Offices and Clinics of Doctors of Medicine',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8021',
    description: 'Offices and Clinics of Dentists',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8031',
    description: 'Offices and Clinics of Doctors of Osteopathy',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8041',
    description: 'Offices and Clinics of Chiropractors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8042',
    description: 'Offices and Clinics of Optometrists',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8043',
    description: 'Offices and Clinics of Podiatrists',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8049',
    description:
      'Offices and Clinics of Health Practitioners, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8051',
    description: 'Skilled Nursing Care Facilities',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8052',
    description: 'Intermediate Care Facilities',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8059',
    description:
      'Nursing and Personal Care Facilities, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8062',
    description: 'General Medical and Surgical Hospitals',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8063',
    description: 'Psychiatric Hospitals',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8069',
    description: 'Specialty Hospitals, Except Psychiatric',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8071',
    description: 'Medical Laboratories',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8072',
    description: 'Dental Laboratories',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8082',
    description: 'Home Health Care Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8092',
    description: 'Kidney Dialysis Centers',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8093',
    description: 'Specialty Outpatient Facilities, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8099',
    description: 'Health and Allied Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8111',
    description: 'Legal Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8211',
    description: 'Elementary and Secondary Schools',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8221',
    description: 'Colleges, Universities, and Professional Schools',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8222',
    description: 'Junior Colleges and Technical Institutes',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8231',
    description: 'Libraries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8243',
    description: 'Data Processing Schools',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8244',
    description: 'Business and Secretarial Schools',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8249',
    description: 'Vocational Schools, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8299',
    description: 'Schools and Educational Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8322',
    description: 'Individual and Family Social Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8331',
    description: 'Job Training and Vocational Rehabilitation Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8351',
    description: 'Child Day Care Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8361',
    description: 'Residential Care',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8399',
    description: 'Social Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8412',
    description: 'Museums and Art Galleries',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8422',
    description: 'Arboreta and Botanical or Zoological Gardens',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8611',
    description: 'Business Associations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8621',
    description: 'Professional Membership Organizations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8631',
    description: 'Labor Unions and Similar Labor Organizations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8641',
    description: 'Civic, Social, and Fraternal Associations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8651',
    description: 'Political Organizations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8661',
    description: 'Religious Organizations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8699',
    description: 'Membership Organizations, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8711',
    description: 'Engineering Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8712',
    description: 'Architectural Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8713',
    description: 'Surveying Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8721',
    description: 'Accounting, Auditing, and Bookkeeping Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8731',
    description: 'Commercial Physical and Biological Research',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8732',
    description: 'Commercial Economic, Sociological, and Educational Research',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8733',
    description: 'Noncommercial Research Organizations',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8734',
    description: 'Testing Laboratories',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8741',
    description: 'Management Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8742',
    description: 'Management Consulting Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8743',
    description: 'Public Relations Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8744',
    description: 'Facilities Support Management Services',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8748',
    description: 'Business Consulting Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8811',
    description: 'Private Households',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '8999',
    description: 'Services, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9111',
    description: 'Executive Offices',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9121',
    description: 'Legislative Bodies',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9131',
    description: 'Executive and Legislative Offices Combined',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9199',
    description: 'General Government, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9211',
    description: 'Courts',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9221',
    description: 'Police Protection',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9222',
    description: 'Legal Counsel and Prosecution',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9223',
    description: 'Correctional Institutions',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9224',
    description: 'Fire Protection',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9229',
    description: 'Public Order and Safety, Not Elsewhere Classified',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9311',
    description: 'Public Finance, Taxation, and Monetary Policy',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9411',
    description: 'Administration of Educational Programs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9431',
    description: 'Administration of Public Health Programs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9441',
    description:
      'Administration of Social, Human Resource and Income Maintenance Programs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9451',
    description:
      "Administration of Veterans' Affairs, Except Health and Insurance",
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9511',
    description: 'Air and Water Resource and Solid Waste Management',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9512',
    description: 'Land, Mineral, Wildlife, and Forest Conservation',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9531',
    description: 'Administration of Housing Programs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9532',
    description:
      'Administration of Urban Planning and Community and Rural Development',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9611',
    description: 'Administration of General Economic Programs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9621',
    description: 'Regulation and Administration of Transportation Programs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9631',
    description:
      'Regulation and Administration of Communications, Electric, Gas, and Other Utilities',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9641',
    description: 'Regulation of Agricultural Marketing and Commodities',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9651',
    description:
      'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9661',
    description: 'Space and Research and Technology',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9711',
    description: 'National Security',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9721',
    description: 'International Affairs',
  },
  {
    type: CompanyClassificationTypeEnum.SIC,
    code: '9999',
    description: 'Nonclassifiable Establishments',
  },
];

export default sicClassifications;
