import { CompanyClassification } from 'shared-domain';
import { CompanyClassificationTypeEnum } from '../type';
import isicClassifications from './isic-classifications';
import naceClassifications from './nace-classifications';
import nafApeClassifications from './naf-ape-classifications';
import sicClassifications from './sic-classifications';

export const companyClassifications: Record<
  CompanyClassificationTypeEnum,
  CompanyClassification[]
> = {
  [CompanyClassificationTypeEnum.ISIC]: isicClassifications,
  [CompanyClassificationTypeEnum.NACE]: naceClassifications,
  [CompanyClassificationTypeEnum.NAF]: nafApeClassifications,
  [CompanyClassificationTypeEnum.SIC]: sicClassifications,
};
