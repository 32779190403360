import { COUNTRIES } from 'shared-domain';
import {
  CompanyClassificationCoverage,
  CompanyClassificationTypeEnum,
} from './type';

export const companyClassificationCoverage: CompanyClassificationCoverage = {
  // worldwide
  [CompanyClassificationTypeEnum.ISIC]: COUNTRIES.map(({ code }) => code),
  // https://connects.world/nace-codes/
  [CompanyClassificationTypeEnum.NACE]: [
    'AT',
    'IE',
    'BE',
    'IT',
    'BG',
    'LT',
    'CY',
    'LU',
    'CZ',
    'LV',
    'DE',
    'MT',
    'DK',
    'NL',
    'EE',
    'PL',
    'EL',
    'PT',
    'ES',
    'RO',
    'FI',
    'SE',
    'FR',
    'SI',
    'HR',
    'SK',
    'HU',
    'UK',
  ],
  // France only
  [CompanyClassificationTypeEnum.NAF]: ['FR'],
  // US / UK
  [CompanyClassificationTypeEnum.SIC]: ['US', 'GB'],
};
