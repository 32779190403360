export enum CompanyClassificationTypeEnum {
  ISIC = 'ISIC',
  NACE = 'NACE',
  NAF = 'NAF',
  SIC = 'SIC',
}

export type CompanyClassificationCoverage = Record<
  CompanyClassificationTypeEnum,
  // array of ISO country code
  string[]
>;
