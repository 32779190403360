import { useEffect, useMemo } from 'react';
import { Box, Button, Heading, Input, VStack } from '@chakra-ui/react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CountrySelect, GroupController } from 'frontend-components';
import {
  generatedFieldName,
  useConfigContext,
  useStore,
  useSubmitCompanyForm,
} from 'frontend-common';
import {
  BaseStepIdEnum,
  COMPANY_NAME_MAX_LENGTH,
  CompanyStatusEnum,
  CustomField,
  customFieldsExtendValidation,
  FieldTypeEnum,
} from 'shared-domain';
import { createYupSchema } from 'shared-common';
import { CompanyClassificationTypeField } from './company-classification/company-classification-type-field';
import { CompanyClassificationCodeField } from './company-classification/company-classification-code-field';

const classificationSchema = Yup.object({
  classifications: Yup.array()
    .transform((value, original) => {
      return original;
    })
    .of(
      Yup.object({
        type: Yup.string()
          .label('Classification type')
          .optional()
          .nullable()
          .default(null)
          .transform((v) => (v === '' ? null : v)),
        code: Yup.string()
          .label('Classification code')
          .when('type', {
            is: (val: string | null) => val && val.length > 0,
            then: (schema) => schema.required(),
            otherwise: (schema) =>
              schema
                .optional()
                .nullable()
                .default(null)
                .transform((v) => (v === '' ? null : v)),
          }),
        description: Yup.string()
          .label('Classification description')
          .optional()
          .nullable()
          .default(null)
          .transform((v) => (v === '' ? null : v)),
      }),
    )
    .nullable(),
});

export const CustomCompanyEdit = () => {
  const config = useConfigContext();
  const { t } = useTranslation();
  const { submitCompanyForm } = useSubmitCompanyForm();
  const { company, queryParams } = useStore();

  const defaultValues = useMemo(() => {
    const defaultCompanyValues: any = {
      name: company?.name || '',
      commercial_name: company?.commercial_name || '',
      registration_number: company?.registration_number || '',
      country: company?.country || '',
      legal_form: company?.legal_form || '',
      status: company?.status || CompanyStatusEnum.not_reported,
      registration_date: company?.registration_date || '',
      address: {
        street_address: company?.address?.street_address || '',
        street_address_2: company?.address?.street_address_2 || '',
        postal_code: company?.address?.postal_code || '',
        city: company?.address?.city || '',
        state: company?.address?.state || '',
        region: company?.address?.region || '',
        country: company?.address?.country || '',
      },
      banking_information: {
        iban: company?.banking_information?.iban || '',
        bic: company?.banking_information?.bic || '',
      },
      tax_identification_number: company?.tax_identification_number || '',
      website_url: company?.website_url || '',
      employer_identification_number:
        company?.employer_identification_number || '',
      share_capital: company?.share_capital || '',
      classifications: [
        {
          type: company?.classifications
            ? company?.classifications[0]?.type || ''
            : '',
          code: company?.classifications
            ? company?.classifications[0]?.code || ''
            : '',
          description: company?.classifications
            ? company?.classifications[0]?.description || ''
            : '',
        },
      ],
    };

    return defaultCompanyValues;
  }, [config.companyFields, company, queryParams]);

  const dynamicValidationSchema = useMemo(() => {
    // Extend fields with our validations
    const dynamicFormData = customFieldsExtendValidation(
      config.companyFields,
      t,
      BaseStepIdEnum.company_edit,
    );
    // Create schema based on added validations
    // @TODO - OPS-9 - Replace Yup by Zod
    return dynamicFormData.reduce(createYupSchema, {});
  }, [config.companyFields]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(classificationSchema.shape(dynamicValidationSchema)),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    setError,
    formState: { isValid, isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, queryParams]);

  useEffect(() => {
    if (getValues('name').length > COMPANY_NAME_MAX_LENGTH) {
      setError('name', {
        type: 'manual',
        message: 'Name must be at most 160 characters',
      });
    }
  }, [setError, getValues]);

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCompanyForm({ companyData: formData });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing="6">
          {config.companyFields
            .filter((field: CustomField) => field.isEnabled)
            .map((field: CustomField) => {
              if (field.type === FieldTypeEnum.country) {
                return (
                  <GroupController
                    key={`company_${generatedFieldName(field)}`}
                    name={generatedFieldName(field)}
                    label={
                      t(
                        `steps.company_edit.${generatedFieldName(field)}.label`,
                      ) || generatedFieldName(field)
                    }
                    helper={
                      field.hasHelper
                        ? t(
                            `steps.company_edit.${generatedFieldName(
                              field,
                            )}.helper`,
                          )
                        : null
                    }
                    isRequired={field.isRequired}
                    control={control}
                    render={(f) => (
                      <CountrySelect
                        onChange={(value: string) => {
                          setValue(generatedFieldName(field), value ?? '', {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        defaultValue={f.value}
                      />
                    )}
                  />
                );
              }

              if (field.type === FieldTypeEnum.url) {
                return (
                  <GroupController
                    key={`company_${generatedFieldName(field)}`}
                    name={generatedFieldName(field)}
                    label={
                      t(
                        `steps.company_edit.${generatedFieldName(field)}.label`,
                      ) || generatedFieldName(field)
                    }
                    helper={
                      field.hasHelper
                        ? t(
                            `steps.company_edit.${generatedFieldName(
                              field,
                            )}.helper`,
                          )
                        : null
                    }
                    isRequired={field.isRequired}
                    control={control}
                    render={(f) => {
                      return (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <Input
                          type={field.type}
                          placeholder="https://mysite.com"
                          maxW="400px"
                          {...f}
                        />
                      );
                    }}
                  />
                );
              }

              return (
                <Box w="100%" key={`company_${generatedFieldName(field)}`}>
                  {field.nested &&
                    t(`steps.company_edit.nested.${field.nested}`) !== '' &&
                    (field.id === 'code' ||
                      field.id === 'street_address' ||
                      field.id === 'iban') && (
                      <Heading
                        as="h3"
                        pt="5"
                        pb="2"
                        fontWeight={600}
                        color="brand.main-3"
                        fontSize={{ base: 'xl', md: '2xl' }}
                      >
                        {t(`steps.company_edit.nested.${field.nested}`)}
                      </Heading>
                    )}
                  <GroupController
                    name={generatedFieldName(field)}
                    label={
                      t(
                        `steps.company_edit.${generatedFieldName(field)}.label`,
                      ) || generatedFieldName(field)
                    }
                    helper={
                      field.hasHelper
                        ? t(
                            `steps.company_edit.${generatedFieldName(
                              field,
                            )}.helper`,
                          )
                        : null
                    }
                    isRequired={
                      field.isRequired ||
                      (generatedFieldName(field) === 'iban' &&
                        !!getValues('banking_information.bic')) ||
                      (generatedFieldName(field) === 'bic' &&
                        !!getValues('banking_information.iban'))
                    }
                    control={control}
                    render={(f) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      return <Input type={field.type} maxW="400px" {...f} />;
                    }}
                  />
                </Box>
              );
            })}

          <CompanyClassificationTypeField />
          <CompanyClassificationCodeField />
        </VStack>

        {config.companyFields
          .filter((field: CustomField) => !field.isEnabled)
          .map((field: CustomField) => {
            return (
              <GroupController
                key={`company_${generatedFieldName(field)}`}
                name={generatedFieldName(field)}
                isRequired={false}
                control={control}
                render={(f) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  return <Input type="hidden" maxW="400px" {...f} />;
                }}
              />
            );
          })}

        <Box mt="6">
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
