import { useMemo, useState } from 'react';
import { GroupController, Select } from 'frontend-components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { companyClassifications } from './classifications/classifications.definition';
import { CompanyClassificationTypeEnum } from './type';

export const CompanyClassificationCodeField = () => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();

  const [searchCode, setSearchCode] = useState('');
  const classification = watch(`classifications.0`);
  const knownType = classification.type in CompanyClassificationTypeEnum;
  const companyClassification = useMemo(
    () =>
      knownType
        ? companyClassifications[
            classification.type as CompanyClassificationTypeEnum
          ]
        : [],
    [classification.type, knownType],
  );

  const options = useMemo(
    () =>
      companyClassification.map(({ code, description }) => ({
        value: code,
        label: `${code} - ${description}`,
      })),
    [companyClassification, searchCode, classification.type],
  );

  return (
    <GroupController
      name="classifications.0.code"
      isRequired={false}
      control={control}
      render={(f) => {
        return (
          <Select
            stepId="custom-company-edit"
            name="classifications.0.code"
            onChange={(value: string) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setValue('classifications.0.code', value ?? '', {
                shouldDirty: true,
                shouldValidate: true,
              });
              setSearchCode(value);
            }}
            options={options}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            defaultValue={f.value}
            placeholder={t(`steps.custom-company-edit.code.placeholder`)}
          />
        );
      }}
    />
  );
};
