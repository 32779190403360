import { useMemo } from 'react';
import { GroupController, Select } from 'frontend-components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { companyClassificationCoverage } from './coverage';
import { CompanyClassificationTypeEnum } from './type';

export const CompanyClassificationTypeField = () => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();

  const availableClassifications = useMemo(
    () =>
      Object.entries(companyClassificationCoverage)
        .filter(
          ([key, countries]) =>
            !watch('country') || countries.includes(watch('country')),
        )
        .map(([key]) => key) as CompanyClassificationTypeEnum[],
    [watch('country')],
  );

  return (
    <GroupController
      name="classifications.0.type"
      label={t(`steps.custom-company-edit.type.label`)}
      isRequired={false}
      control={control}
      render={(f) => {
        return (
          <Select
            stepId="custom-company-edit"
            name="classifications.0.type"
            onChange={(value: string) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setValue('classifications.0.type', value ?? '', {
                shouldDirty: true,
                shouldValidate: true,
              });
              setValue('classifications.0.code', '', {
                shouldDirty: true,
                shouldValidate: false,
              });
            }}
            options={availableClassifications}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            defaultValue={f.value}
            placeholder={t(`steps.custom-company-edit.type.placeholder`)}
          />
        );
      }}
    />
  );
};
